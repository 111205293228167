import React, { useEffect, useState } from "react"
import { Modal, Row, Col } from "react-bootstrap"
import { Formik } from "formik"
import * as Yup from "yup"
import {
  InfoItemHorizontal,
  TextArea,
  ActionButton,
  SelectSearch,
  DatePicker,
  Input,
} from "components"
import { RupiahConvert, DateConvert } from "utilities"
import { BDPProduksiApi } from "api"

const ModalJurnalBDP = ({ modalConfig, setModalConfig, getData }) => {
  const today = DateConvert(new Date()).default
  const [dropdownCOA, setDropdownCOA] = useState([])
  const dataModal = modalConfig?.data ?? {}

  const formInitialValues = {
    id_realisasi_produksi_overhead: dataModal?.id_realisasi_produksi_overhead ?? "",
    tgl_realisasi_produksi_overhead: dataModal?.tgl_realisasi_produksi ?? "-",
    no_realisasi_produksi_overhead: dataModal?.no_realisasi_produksi ?? "-",
    nama_item: dataModal?.nama_item ?? "-",
    qty_realisasi_produksi_overhead: dataModal?.qty_realisasi_overhead ?? 0,
    harga_satuan_overhead: dataModal?.harga_satuan_overhead ?? 0,

    tgl_bdp_overhead: "",
    no_bdp_overhead: "",
    jumlah: (dataModal?.qty_realisasi_overhead ?? 0) * (dataModal?.harga_satuan_overhead ?? 0),
    id_coa_debet: "1010505",
    id_coa_kredit: "1010603",
    keterangan_jurnal: "",
  }

  const formValidationSchema = Yup.object().shape({
    tgl_bdp_overhead: Yup.string().required("Pilih tanggal BDP Overhead"),
    no_bdp_overhead: Yup.string().required("Pilih tgl. realisasi untuk menentukan nomor"),
    id_coa_debet: Yup.string().required("Pilih COA Debet"),
    id_coa_kredit: Yup.string().required("Pilih COA Kredit"),
    keterangan_jurnal: Yup.string().required("Keterangan Jurnal wajib diisi"),
  })

  const formSubmitHandler = (values, { setSubmitting }) => {
    const finalValue = {
      ...values,
      nomor_akun_debet: values.id_coa_debet,
      nomor_akun_kredit: values.id_coa_kredit,
      nama_coa_debet: dropdownCOA.find((item) => item.value === values.id_coa_debet)?.label ?? "",
      nama_coa_kredit: dropdownCOA.find((item) => item.value === values.id_coa_kredit)?.label ?? "",
    }

    // Send to server
    BDPProduksiApi.postBDPOverhead(finalValue)
      .then(() => getData())
      .finally(() => {
        setSubmitting(false)
        setModalConfig({ show: false, data: {} })
      })
  }

  const fetchCOA = () => {
    BDPProduksiApi.getCOA()
      .then((res) => {
        const coa = res?.data?.data ?? []
        const mapcoa = coa.map((item) =>
          Object({ ...item, value: item.id_coa, label: `${item.nomor_akun} - ${item.nama_akun}` })
        )
        setDropdownCOA(mapcoa ?? [])
      })
      .catch(() => alert("Gagal memuat data COA!"))
  }

  const InfoSection = ({ values }) => {
    return (
      <table>
        <tbody>
          <InfoItemHorizontal
            label="Tgl. Realisasi Produksi"
            text={DateConvert(new Date(values.tgl_realisasi_produksi_overhead)).detail}
          />
          <InfoItemHorizontal
            label="No. Realisasi Produksi"
            text={values.no_realisasi_produksi_overhead}
          />
          <InfoItemHorizontal label="Item Overhead" text={values.nama_item} />
          <InfoItemHorizontal label="Qty." text={values.qty_realisasi_produksi_overhead} />
          <InfoItemHorizontal
            label="Harga Satuan"
            text={RupiahConvert(String(parseInt(values.harga_satuan_overhead))).detail}
          />
        </tbody>
      </table>
    )
  }

  const FormBody = ({ formik }) => {
    const {
      values,
      touched,
      errors,
      isSubmitting,
      setValues,
      setFieldValue,
      handleChange,
      handleSubmit,
    } = formik

    const getNoHandler = (tanggal = today) => {
      BDPProduksiApi.getNomorOverhead({ tanggal }).then((res) => {
        setValues({
          ...values,
          tgl_bdp_overhead: tanggal,
          no_bdp_overhead: res.data.data,
        })
      })
    }

    useEffect(() => getNoHandler(), [])

    return (
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <InfoSection values={values} />
          <hr />
          <Row>
            <Col md>
              <DatePicker
                label="Tgl. BDP"
                type="date"
                placeholderText="Pilih tgl. BDP"
                selected={values.tgl_bdp_overhead ? new Date(values.tgl_bdp_overhead) : ""}
                onChange={(date) => {
                  const newDate = DateConvert(date).default
                  getNoHandler(newDate)
                }}
                error={Boolean(errors.tgl_bdp_overhead) && touched.tgl_bdp_overhead}
                errorText={errors.tgl_bdp_overhead}
              />
            </Col>
            <Col md>
              <Input
                label="No. BDP"
                type="text"
                placeholder="Pilih tgl. BDP"
                value={values.no_bdp_overhead}
                error={Boolean(errors.no_bdp_overhead) && touched.no_bdp_overhead}
                errorText={errors.no_bdp_overhead}
                readOnly
              />
            </Col>
          </Row>
          <Input
            label="Jumlah"
            type="text"
            name="harga_satuan_overhead"
            value={RupiahConvert(parseInt(values.jumlah).toString()).detail}
            className="text-right"
            readOnly
          />
          <SelectSearch
            label="COA Debet"
            name="id_coa_debet"
            placeholder="Pilih COA Debet"
            onChange={(val) => setFieldValue("id_coa_debet", val.value)}
            option={dropdownCOA}
            defaultValue={dropdownCOA.map((item) =>
              item.id_coa === values.id_coa_debet ? item : ""
            )}
            error={errors.id_coa_debet && touched.id_coa_debet && true}
            errorText={errors.id_coa_debet && touched.id_coa_debet && errors.id_coa_debet}
          />
          <SelectSearch
            label="COA Kredit"
            name="id_coa_kredit"
            placeholder="Pilih COA Kredit"
            onChange={(val) => setFieldValue("id_coa_kredit", val.value)}
            option={dropdownCOA}
            defaultValue={dropdownCOA.map((item) =>
              item.id_coa === values.id_coa_kredit ? item : ""
            )}
            error={errors.id_coa_kredit && touched.id_coa_kredit && true}
            errorText={errors.id_coa_kredit && touched.id_coa_kredit && errors.id_coa_kredit}
          />
          <TextArea
            label="Keterangan Jurnal*"
            name="keterangan_jurnal"
            value={values.keterangan_jurnal}
            onChange={handleChange}
            error={errors.keterangan_jurnal && touched.keterangan_jurnal && true}
            errorText={errors.keterangan_jurnal}
            rows={5}
          />
        </Modal.Body>
        <Modal.Footer>
          <ActionButton
            variant="primary"
            type="submit"
            text="Simpan"
            className="px-4 text-white"
            loading={isSubmitting}
          />
        </Modal.Footer>
      </form>
    )
  }

  useEffect(() => {
    fetchCOA()

    return () => { }
  }, [])

  return (
    <Modal show={modalConfig.show} onHide={() => setModalConfig({ data: {}, show: false })}>
      <Modal.Header closeButton>
        <b>Tambah Data Jurnal BDP</b>
      </Modal.Header>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {(formik) => <FormBody formik={formik} />}
      </Formik>
    </Modal>
  )
}

export default ModalJurnalBDP
