import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Row, Col, Modal, Button } from 'react-bootstrap'
import { Alert, ActionButton, DatePicker, Input, SelectSearch, TextArea } from 'components'
import { InfoSectionModal } from '../InfoSection'
import { TransferOverheadProduksiApi } from 'api'
import {
  RupiahConvert
} from 'utilities'

const ModalForm = ({ modalConfig, setModalConfig }) => {
  const { id_permintaan_produksi_overhead, id_item_buaso, id_permintaan_produksi, qty_produksi, qty_transfer, hps_overhead } = modalConfig.data
  const history = useHistory()
  const TODAY = new Date()
  const [alertConfig, setAlertConfig] = useState({ show: false, variant: 'primary', text: 'test' })
  const formInitialValues = {
    id_permintaan_produksi: id_permintaan_produksi,
    id_permintaan_produksi_overhead: id_permintaan_produksi_overhead,
    tgl_transfer_produksi_overhead: TODAY,
    id_item_buaso: id_item_buaso,
    no_permintaan_produksi: "",
    qty_transfer: 0,
    harga_satuan: parseInt(hps_overhead ?? 0),
    keterangan_transfer: ""
  }
  
  const formValidationSchema = Yup.object().shape({
    tgl_transfer_produksi_overhead: Yup.string().required("Pilih tanggal transfer produksi"),
    harga_satuan: Yup.string().required("Masukkan harga satuan"),
    qty_transfer: Yup.string().required("Masukkan qty. transfer")
      .test("checkQty", "Qty. Transfer tidak dapat melebihi Qty. Permintaan Produksi", (value) => parseInt(value) <= (qty_produksi - qty_transfer) ? true : false),
  })

  const formSubmitHandler = (values, { setSubmitting }) => {
    const finalValue = { ...values, harga_satuan_overhead: values.harga_satuan }

    TransferOverheadProduksiApi.save(finalValue)
      .then(() => {
        history.push("/transaksi/transfer-overhead-produksi", {
          alert: { show: true, variant: 'primary', text: 'Data berhasil disimpan' }
        })
      })
      .catch(() => setAlertConfig({ show: true, variant: 'danger', text: 'Gagal menyimpan data!' }))
      .finally(() => setSubmitting(false))
  }

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: formSubmitHandler,
  })

  const { values, errors, touched, handleChange, setValues, setFieldValue, handleSubmit, isSubmitting, } = formik

  const getNomorHandler = (date = TODAY) => {
    TransferOverheadProduksiApi.getNomor({ tanggal: date }).then((res) => {
      const nomorBaru = res.data.data

      setValues({
        ...values,
        tgl_permintaan_produksi: date,
        no_permintaan_produksi: nomorBaru,
      })
    })
  }

  useEffect(() => {
    getNomorHandler()
    return () => { }
  }, [])

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({ show: false, data: {} })}
    >
      <Modal.Header closeButton>
        <b>Tambah Data Transfer Overhead Produksi</b>
      </Modal.Header>
      <Modal.Body>
        <Alert
          showCloseButton
          onClose={() => setAlertConfig({ ...alertConfig, show: false })}
          {...alertConfig}
        />
        <InfoSectionModal data={modalConfig.data} />
        <Row className="d-flex justify-content-start align-items-center">
          <Col>
            <DatePicker
              label="Tgl. Transfer Overhead Produksi"
              selected={values.tgl_permintaan_produksi}
              onChange={(date) => getNomorHandler(date)}
              error={Boolean(errors.tgl_permintaan_produksi && touched.tgl_permintaan_produksi)}
              errorText={Boolean(errors.tgl_permintaan_produksi && touched.tgl_permintaan_produksi) && errors.tgl_permintaan_produksi}
            />
          </Col>
          <Col>
            <Input
              readOnly
              label="No. Transfer Overhead Produksi"
              value={values.no_permintaan_produksi}
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-start align-items-center">
          <Col md={3}>
            <Input
              type="number"
              label="Qty. Transfer"
              name="qty_transfer"
              className="text-right"
              value={values.qty_transfer}
              onChange={handleChange}
              error={Boolean(errors.qty_transfer && touched.qty_transfer)}
              errorText={Boolean(errors.qty_transfer && touched.qty_transfer) && errors.qty_transfer}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              label="Harga Satuan"
              placeholder="Masukan harga satuan"
              value={values?.harga_satuan ? RupiahConvert(String(values.harga_satuan)).detail : ''}
              onChange={e => {
                setFieldValue('harga_satuan', RupiahConvert(String(e?.target?.value)).default || '')
              }}
              error={Boolean(errors.harga_satuan && touched.harga_satuan)}
              errorText={Boolean(errors.harga_satuan && touched.harga_satuan) && errors.harga_satuan}
            />
          </Col>
          <Col>
            <Input
              label="Total Harga"
              placeholder="Masukan harga satuan"
              value={values?.harga_satuan ? RupiahConvert(String(parseInt(values?.harga_satuan || 0) * parseFloat(values?.qty_transfer || 0))).detail : ''}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextArea
              label="Keterangan Transfer"
              value={values.keterangan_transfer}
              name="keterangan_transfer"
              rows={5}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => setModalConfig({ show: false, data: {} })}
        >
          BATAL
        </Button>

        <ActionButton
          text="Simpan"
          loading={isSubmitting}
          onClick={handleSubmit} />
      </Modal.Footer>
    </Modal >
  )
}

export default ModalForm