import Services from "services";

class RegItemOverheadApi {
  getPage(page, dataLength, key) {
    return Services.get(`/overhead/page/?page=${page}&per_page=${dataLength}&q=${key}`);
  }
  getBuaso() {
    return Services.get("/buaso");
  }
  getSatuan() {
    return Services.get("/satuan/dropdown");
  }
  single(params) {
    return Services.get("/overhead/single", { params });
  }
  getKelompok() {
    return Services.get("/overhead/kelompok?id_buaso=5");
  }
  generateKodeItem() {
    return Services.get("/overhead/no_baru");
  }
  create(data) {
    return Services.post("/overhead", data);
  }
  update(data) {
    return Services.put("/overhead", data);
  }
  delete(id_overhead) {
    return Services.post("/overhead/delete", id_overhead);
  }
  search(key) {
    return Services.get(`overhead/page/?q=${key}`);
  }
  show(id_overhead) {
    return Services.put("/overhead/show", id_overhead);
  }
  hide(id_overhead) {
    return Services.put("/overhead/hide", id_overhead);
  }
  export() {
    return Services.get("/overhead/xlsx");
  }
}

export default new RegItemOverheadApi();
