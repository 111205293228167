import React from 'react'
import RDatePicker from 'react-datepicker'

const DatePicker = ({
  label,
  error,
  errorText,
  ...rest
}) => {
  return (
    <div className="mb-2 d-flex flex-column" style={{paddingTop: '5px'}}>
      <small>{label}</small>
      <RDatePicker 
        {...rest}
        className={`form-control form-control-sm ${error && "is-invalid"}`}
      />
      <small className="text-danger pt-1">{error && errorText}</small>
    </div>
  )
}

export default DatePicker
