import {
    useState,
    useEffect
  } from 'react'
  import {
    Card,
    Row,
    Col,
    Tabs,
    Tab
  } from 'react-bootstrap'
  import {
    useHistory
  } from 'react-router-dom'
  import Axios from 'axios'
  import {
    Formik
  } from 'formik'
  import * as Yup from 'yup'
  import {
    ActionButton,
    BackButton,
    DataStatus,
    InfoItemHorizontal,
    InfoItemVertical
  } from 'components'
  import {
    BDPProduksiApi,
    RealisasiProduksiApi
  } from 'api'
  import { TabItem } from './TabItem'
  import { DateConvert } from 'utilities'
  
  export const DetailRealisasi = ({ modalConfig, setModalConfig }) => {
    const { id_realisasi_produksi = null } = modalConfig ?? {}
    const [tab, setTab] = useState("bahan")
    const [dataDetail, setDataDetail] = useState([])
    const [dataBahan, setDataBahan] = useState([])
    const [dataUpah, setDataUpah] = useState([])
    const [dataSubkon, setDataSubkon] = useState([])
    const [dataOverhead, setDataOverhead] = useState([])
    const [dataHistory, setDataHistory] = useState({})
    const [fetching, setFetching] = useState({
      loading: true,
      error: false
    })
  
    const mapDataItem = (dataItem = []) => dataItem ? dataItem.map(val => ({
      id: val?.id_transfer_produksi_bahan ?? val.id_transfer_produksi_upah ?? val.id_transfer_produksi_subkon ?? val.id_transfer_produksi_overhead,
      tgl_transfer: val?.tgl_transfer_produksi_bahan ?? val.tgl_transfer_produksi_upah ?? val.tgl_transfer_produksi_subkon ?? val.tgl_transfer_produksi_overhead,
      no_transfer: val?.no_transfer_produksi_bahan ?? val.no_transfer_produksi_upah ?? val.no_transfer_produksi_subkon ?? val.no_transfer_produksi_overhead,
      nama_item: val?.nama_item_buaso ?? val?.nama_item,
      nama_vendor: val?.nama_vendor,
      satuan: val?.nama_satuan,
      qty_transfer: val?.qty_transfer,
      qty_realisasi: val?.qty_realisasi_bahan ?? val.qty_realisasi_upah ?? val.qty_realisasi_subkon ?? val.qty_realisasi_overhead,
      qty_terealisasi: val?.sum_realisasi ?? 0
    })) : []
  
    const mapDataHistory = (dataItem = []) => dataItem ? dataItem.map(val => ({
      id: val?.id_realisasi_produksi_bahan ?? val.id_realisasi_produksi_upah ?? val.id_realisasi_produksi_subkon ?? val.id_realisasi_produksi_overhead,
      tgl_realisasi: val?.tgl_realisasi_produksi,
      no_realisasi: val?.no_realisasi_produksi,
      nama_item: val?.nama_item,
      nama_vendor: val?.nama_vendor,
      satuan: val?.nama_satuan,
      qty_realisasi: val?.qty_realisasi_bahan ?? val.qty_realisasi_upah ?? val.qty_realisasi_subkon ?? val.qty_realisasi_overhead,
    })) : []
  
    const getInitialData = () => Axios.all([
      BDPProduksiApi.getRealisasiSingle({ id_realisasi_produksi }),
      BDPProduksiApi.getRealisasiHistory({ id_realisasi_produksi })
    ])
      .then(Axios.spread((data, history) => {
        const getDataDetail = data?.data?.data ?? {}
        const getDataHistory = history?.data?.data ?? []
  
        setDataDetail(getDataDetail)
        setDataBahan(mapDataItem(getDataDetail?.bahan))
        setDataUpah(mapDataItem(getDataDetail?.upah))
        setDataSubkon(mapDataItem(getDataDetail?.subkon))
        setDataOverhead(mapDataItem(getDataDetail?.overhead))
        setDataHistory({
          bahan: mapDataHistory(getDataHistory?.bahan),
          upah: mapDataHistory(getDataHistory?.upah),
          subkon: mapDataHistory(getDataHistory?.subkon),
          overhead: mapDataHistory(getDataHistory?.overhead)
        })
        setFetching({
          loading: false,
          error: false
        })
      }))
      .catch(() => {
        setFetching({
          loading: false,
          error: true
        })
      })
  
    useEffect(() => {
      console.log('runeffect')
      if (id_realisasi_produksi) {
        console.log(id_realisasi_produksi)
        getInitialData()
      }
    }, [])
  
    if (fetching.loading || fetching.error) {
      return (
        <>
          <DataStatus
            loading={fetching.loading}
            text={fetching.loading ? 'Memuat data . . .' : 'Data gagal dimuat'}
          />
        </>
      )
    }
  
    return (
      <Formik>
        {() => (
          <>
            {/* Info Section */}
            <Card className="mb-4">
              <Card.Body>
                <Row>
                  <Col>
                    <InfoItemHorizontal label="Tgl. Job Order" text={dataDetail?.tgl_job_order ? DateConvert(new Date(dataDetail?.tgl_job_order)).detail : "-"} />
                    <InfoItemHorizontal label="No. Job Order" text={dataDetail?.no_job_order ?? '-'} />
                    <InfoItemHorizontal label="Kode Item Produksi" text={dataDetail?.kode_item ?? '-'} />
                    <InfoItemHorizontal label="Item Produksi" text={dataDetail?.nama_item ?? '-'} />
                    <InfoItemHorizontal label="Keperluan" text={dataDetail?.keperluan_produksi ?? '-'} />
                  </Col>
                  <Col>
                    <InfoItemHorizontal label="Qty. Job Order" text={dataDetail?.qty_produksi ? `${parseFloat(dataDetail.qty_produksi)} ${dataDetail?.nama_satuan ?? ""}` : '-'} />
                    <InfoItemHorizontal label="Total Qty. Telah Direalisasi" text={dataDetail?.qty_realisasi_produksi ? `${parseFloat(dataDetail.qty_realisasi_produksi)} ${dataDetail?.nama_satuan ?? ""}` : '-'} />
                    <InfoItemHorizontal label="Petugas Produksi" text={dataDetail?.nama_karyawan ?? '-'} />
                    <InfoItemHorizontal label="Depo Produksi" text={dataDetail?.nama_depo ?? '-'} />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <InfoItemVertical label="Tgl. Realisasi Produksi" text={dataDetail?.tgl_realisasi_produksi ? DateConvert(new Date(dataDetail?.tgl_realisasi_produksi)).detail : "-"} />
                  </Col>
                  <Col>
                    <InfoItemVertical label="No. Realisasi Produksi" text={dataDetail?.no_realisasi_produksi ?? "-"} />
                  </Col>
                  <Col>
                    <InfoItemVertical label="Qty. Realisasi Item Produksi" text={dataDetail?.no_realisasi_produksi ?? "-"} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
  
            {/* Tabs Section */}
            <div className="mt-4 mb-1">
              <b className="px-2">Data Realisasi Produksi</b>
            </div>
            <Tabs
              activeKey={tab}
              onSelect={key => setTab(key)}
              transition={false}
            >
              <Tab
                eventKey="bahan"
                title="Realisasi Bahan"
                children={
                  <TabItem
                    action="DETAIL"
                    type="Bahan"
                    dataRealisasi={dataBahan}
                    dataHistory={dataHistory?.bahan}
                    setDataRealisasi={setDataBahan}
                  />
                }
              />
              <Tab
                eventKey="upah"
                title="Realisasi Upah"
                children={
                  <TabItem
                    action="DETAIL"
                    type="Upah"
                    dataRealisasi={dataUpah}
                    dataHistory={dataHistory?.upah}
                    setDataRealisasi={setDataUpah}
                  />
                }
              />
              {/* <Tab
                eventKey="subkon"
                title="Realisasi Subkon"
                children={
                  <TabItem
                    action="DETAIL"
                    type="Subkon"
                    dataRealisasi={dataSubkon}
                    dataHistory={dataHistory?.subkon}
                    setDataRealisasi={setDataSubkon}
                  />
                }
              /> */}
              <Tab
                eventKey="overhead"
                title="Realisasi Overhead"
                children={
                  <TabItem
                    action="DETAIL"
                    type="Overhead"
                    dataRealisasi={dataOverhead}
                    dataHistory={dataHistory?.overhead}
                    setDataRealisasi={setDataOverhead}
                  />
                }
              />
            </Tabs>
          </>
        )}
      </Formik>
    )
  }