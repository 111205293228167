import { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { DatePicker, SelectSearch } from 'components'
import { DateConvert } from 'utilities'
import { Formik } from "formik"

const ModalFilterTransfer = ({ dropdown, modalConfig, setModalConfig, data, setData }) => {
  const formInitialValues = {
    tgl_transfer_produksi_overhead_awal: data?.filter?.tgl_transfer_produksi_overhead_awal,
    tgl_transfer_produksi_overhead_akhir: data?.filter?.tgl_transfer_produksi_overhead_akhir,
    tgl_permintaan_produksi_awal: data?.filter?.tgl_permintaan_produksi_awal,
    tgl_permintaan_produksi_akhir: data?.filter?.tgl_permintaan_produksi_akhir,
    id_item_buaso: data?.filter?.id_item_buaso,
  }
  const formSubmitHandler = (values) => {
    const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

    if (checkActive) {
      setData({ 
          filter: {
              ...values,
              active: true
          },
          pagination: {
              ...data.pagination,
              page: 1
          }
      })
    } else { 
        setData({ 
            ...data,
            filter: {
                ...values,
                active: false
            }
        })
    }

    setModalConfig(prev => ({...prev, show: false}))
  }

  const onChangeTransferProduksi = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_transfer_produksi_overhead_awal: startDate,
        tgl_transfer_produksi_overhead_akhir: endDate,
    })
  }
  const onChangePermintaanProduksi = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_permintaan_produksi_awal: startDate,
        tgl_permintaan_produksi_akhir: endDate,
    })
  }
  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_transfer_produksi_overhead_awal: undefined,
      tgl_transfer_produksi_overhead_akhir: undefined,
      tgl_permintaan_produksi_awal: undefined,
      tgl_permintaan_produksi_akhir: undefined,
      id_item_buaso: undefined,
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      
      {({values, handleSubmit, setFieldValue, setValues}) => (
        <Modal
          show={modalConfig.show && modalConfig.type === 'filter'}
          onHide={() => setModalConfig(prev => ({ ...prev, show: false }))}>

          <Modal.Header closeButton>
            <b>Filter Data</b>
          </Modal.Header>
          <Modal.Body>
            <DatePicker
              selectsRange
              label="Tgl. Transfer Produksi"
              placeholderText="Pilih tanggal transfer produksi"
              startDate={values.tgl_transfer_produksi_overhead_awal ? new Date(values.tgl_transfer_produksi_overhead_awal) : ''}
              endDate={values.tgl_transfer_produksi_overhead_akhir ? new Date(values.tgl_transfer_produksi_overhead_akhir) : ''}
              onChange={(dates) => onChangeTransferProduksi(dates, values, setValues)}
              monthsShown={2}
            />
            <DatePicker
              selectsRange
              label="Tgl. Permintaan Produksi"
              placeholderText="Pilih tanggal permintaan produksi"
              startDate={values.tgl_permintaan_produksi_awal ? new Date(values.tgl_permintaan_produksi_awal) : ''}
              endDate={values.tgl_permintaan_produksi_akhir ? new Date(values.tgl_permintaan_produksi_akhir) : ''}
              onChange={(dates) => onChangePermintaanProduksi(dates, values, setValues)}
              monthsShown={2}
            />
            <SelectSearch
              key={values.id_item_buaso}
              label="Item Overhead"
              placeholder="Pilih item overhead"
              defaultValue={dropdown?.overhead?.find(item => item.value === values.id_item_buaso)}
              option={dropdown.overhead}
              onChange={val => setFieldValue('id_item_buaso', val.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              onClick={() => onResetButtonClick(values, setValues)}
            >
              RESET
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={handleSubmit}
            >
              FILTER
            </Button>
          </Modal.Footer>
        </Modal >
      )}
    </Formik>
  )
}

export default ModalFilterTransfer