import React, { useState, memo } from "react"
import { IoAddOutline, IoCheckmarkOutline } from "react-icons/io5"
import { DataStatus, Table, Th, ThFixed, Td, ActionButton, Tr, THead, TdFixed } from "components"
import { RupiahConvert } from "utilities"
import { ModalForm } from ".."

const ItemRow = memo(({ setModalConfig, index, val, isInHistory }) => {
  const tableStyling = { width: 100, padding: 0, fontSize: "14px" }
  const sub_total = Math.round(parseFloat(val?.qty_compliment || 0) * parseFloat(val.harga_satuan || 0))

  return (
    <>
    <Tr>
      <TdFixed width={20}><div className="text-center">{index + 1}</div></TdFixed>
      <Td>{val?.kode_item ?? "-"}</Td>
      <Td>{val?.nama_item ?? "-"}</Td>
      <Td>{val?.nama_gudang ?? "-"}</Td>
      <Td>{val?.nama_satuan ?? "-"}</Td>
      <Td style={tableStyling} className="text-right">{parseFloat(val.qty_compliment ?? 0)}</Td>
      <Td style={tableStyling} className="text-right">{val?.harga_satuan ? RupiahConvert(parseInt(val.harga_satuan || 0).toString()).getWithComa : "Rp.0"}</Td>
      <Td style={tableStyling} className="text-right">{sub_total ? RupiahConvert(parseInt(sub_total || 0).toString()).getWithComa : "Rp.0"}</Td>
      <Td>
        {isInHistory
          ? (
            <ActionButton disable size="sm" variant="success" >
              <IoCheckmarkOutline />
            </ActionButton>
          )
          : (
            <ActionButton size="sm" onClick={() => setModalConfig({ show: true, data: val, tipe: "posting" })} >
              <IoAddOutline />
            </ActionButton>
          )
        }
      </Td>
    </Tr>
    </>
  )
})

const TableItemCompliment = ({ data, setData, dataInfo, dataHistory }) => {
  const [modalConfig, setModalConfig] = useState({ show: false, data: {}, tipe: "" })
  const total = data?.reduce((acc, val) => Math.round(acc + parseFloat(val?.qty_compliment || 0) * parseFloat(val?.harga_satuan || 0)), 0)

  return (
    <React.Fragment>
      <div className="table-responsive">
        <Table>
          <THead className="text-center">
            <Tr>
              <ThFixed>No.</ThFixed>
              <Th width={100}>Kode Item</Th>
              <Th>Item Barang </Th>
              <Th width={150}>Gudang</Th>
              <Th width={80}>Satuan</Th>
              <Th width={100}>Qty. Compliment</Th>
              <Th width={140}>Harga Satuan</Th>
              <Th width={140}>Sub Total</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <tbody>
            {data?.map((val, index) => {
              const isInHistory = dataHistory?.find(e => e.id_compliment_detail === val.id_compliment_detail && true)
              return (
                <ItemRow
                  key={index}
                  index={index}
                  val={val}
                  data={data}
                  setData={setData}
                  modalConfig={modalConfig}
                  setModalConfig={setModalConfig}
                  isInHistory={isInHistory}
                />
            )})}
            <Tr>
              <Td colSpan={7} className="text-right"><b>Total</b></Td>
              <Td className="text-right">{total ? RupiahConvert(parseInt(total || 0).toString()).getWithComa : "Rp.0"}</Td>
              <Td></Td>
            </Tr>

            {/* Handle tidak ada data */}
            {data?.length === 0 && ( <tr><Td colSpan={7} textCenter><DataStatus text="Tidak ada data" /></Td></tr> )}
          </tbody>
        </Table>
      </div>

      {modalConfig.show && ( <ModalForm modalConfig={modalConfig} setModalConfig={setModalConfig} dataInfo={dataInfo} /> )}
    </React.Fragment>
  )
}

export default TableItemCompliment
