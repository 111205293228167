import React from 'react'
import { 
  Table,
  THead,
  TBody,
  Th,
  Td,
  ThFixed,
  DataStatus,
  Input
} from 'components'
import {
  DateConvert
} from 'utilities'

const decimalConvert = (value) => {
  const newValue = value.toString().replace(/[^0-9\.]/g, "")
  const convert = newValue.substring(0, 10)

  return convert
}

export const TabItem = ({type, action, dataRealisasi = [], dataHistory = [], setDataRealisasi}) => {
  return (
    <div className="bg-white p-3 border-top-0 border">

      {/* List Realisasi Item */}
      <div className="p-2">
        <b>List Realisasi Item {type ?? ''}</b>
      </div>
      <Table>
        <THead>
          <tr>
            <ThFixed>No.</ThFixed>
            <Th style={{width: 150}}>Informasi Transfer {type ?? ''} Produksi</Th>
            <Th style={{minWidth: 200}}>Item {type ?? ''}</Th>
            {type === 'Upah' || type === 'Subkon' ? <Th style={{minWidth: 200}}>Vendor</Th> : <></>}
            <Th style={{width: 100}}>Satuan</Th>
            <Th style={{width: 150}}>Qty. Transfer {type ?? ''} Produksi</Th>
            <Th style={{width: 150}}>Total Qty. Telah Direalisasi</Th>
            <Th style={{width: 150}}>Qty. Realisasi {type ?? ''}</Th>
          </tr>
        </THead>
        <TBody>
          {dataRealisasi && dataRealisasi?.length > 0
            ? dataRealisasi.map((val, index) => (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td>
                    <span className="d-block">{val?.tgl_transfer ? DateConvert(new Date(val.tgl_transfer)).defaultDMY : '-'}</span>
                    <span className="d-block">{val?.no_transfer ?? '-'}</span>
                  </Td>
                  <Td>{val?.nama_item ?? '-'}</Td>
                  {type === 'Upah' || type === 'Subkon' ? <Td>{val?.nama_vendor ?? '-'}</Td> : <></>}
                  <Td>{val?.satuan ?? '-'}</Td>
                  <Td textRight>{val?.qty_transfer ? parseFloat(val.qty_transfer) : '-'}</Td>
                  <Td textRight>{val?.qty_terealisasi ? parseFloat(val.qty_terealisasi) : '0'}</Td>
                  <Td textRight>
                    {action === 'DETAIL'
                      ? val?.qty_realisasi ? parseFloat(val.qty_realisasi) : '-'
                      : <Input 
                          noMargin
                          value={val.qty_realisasi}
                          disabled={parseFloat(val.qty_terealisasi ?? 0) >= parseFloat(val.qty_transfer ?? 0)}
                          onChange={(e) => {
                            const qty = decimalConvert(parseFloat(e.target.value || 0))
                            const available = parseFloat(val.qty_transfer ?? 0) - parseFloat(val.qty_terealisasi ?? 0) 
                            const checkQty = qty <= available ? qty : available
                            let newData = [...dataRealisasi]
                            newData.splice(index, 1, {...val, qty_realisasi: checkQty})
                            setDataRealisasi(newData)
                          }}
                        />
                    }
                  </Td>
                </tr>
              ))
            : <tr>
                <Td colSpan={10}>
                  <DataStatus 
                    text="Tidak ada data"
                  />
                </Td>
              </tr>
          }
        </TBody>
      </Table>

      {/* History Realisasi */}
      <div className="p-2 mt-4">
        <b>History Realisasi Item {type ?? ''}</b>
      </div>
      <Table>
        <THead>
          <tr>
            <ThFixed>No.</ThFixed>
            <Th style={{width: 150}}>Informasi Realisasi Produksi</Th>
            <Th style={{minWidth: 200}}>Item {type ?? ''}</Th>
            {type === 'Upah' || type === 'Subkon' ? <Th style={{minWidth: 200}}>Vendor</Th> : <></>}
            <Th style={{width: 100}}>Satuan</Th>
            <Th style={{width: 150}}>Qty. Realisasi {type ?? ''}</Th>
          </tr>
        </THead>
        <TBody>
          {dataHistory && dataHistory?.length > 0
            ? dataHistory.map((val, index) => (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td>
                    <span className="d-block">{val?.tgl_realisasi ? DateConvert(new Date(val.tgl_realisasi)).defaultDMY : '-'}</span>
                    <span className="d-block">{val?.no_realisasi ?? '-'}</span>
                  </Td>
                  <Td>{val?.nama_item ?? '-'}</Td>
                  {type === 'Upah' || type === 'Subkon' ? <Td>{val?.nama_vendor ?? '-'}</Td> : <></>}
                  <Td>{val?.satuan ?? '-'}</Td>
                  <Td textRight>{val?.qty_realisasi ? parseFloat(val.qty_realisasi) : '-'}</Td>
                </tr>
              ))
            : <tr>
                <Td colSpan={10}>
                  <DataStatus 
                    text="Tidak ada data"
                  />
                </Td>
              </tr>
          }
        </TBody>
      </Table>
    </div>
  )
}