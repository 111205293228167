import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Row, Col, ButtonGroup } from "react-bootstrap"
import axios from "axios"
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  CreateButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  Tr,
  TBody,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ReadButton,
  FilterButton
} from "components"
import { TableNumber, DateConvert, RupiahConvert } from "utilities"
import { TransferOverheadProduksiApi } from "api"
import { ModalDetail, ModalFilterTransfer } from "./Section"

const ListTransferOverheadProduksi = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  // const paginationFromBrowserState = location?.state?.pagination
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [dataTransfer, setDataTransfer] = useState([])
  // const [paginationConfig, setPaginationConfig] = useState({
  //   page: paginationFromBrowserState?.page ?? "1",
  //   dataLength: paginationFromBrowserState?.dataLength ?? "10",
  //   totalPage: paginationFromBrowserState?.totalPage ?? "1",
  //   dataCount: paginationFromBrowserState?.dataCount ?? "0",
  // })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  const [modalConfig, setModalConfig] = useState({ show: false, type: 'detail', data: {} })
  // const initialFilter = {
  //   isFilter: false,
  //   tgl_transfer_produksi_overhead_awal: undefined,
  //   tgl_transfer_produksi_overhead_akhir: undefined,
  //   tgl_permintaan_produksi_awal: undefined,
  //   tgl_permintaan_produksi_akhir: undefined,
  //   id_item_buaso: undefined,
  // }
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.transfer?.filter?.active,
      tgl_transfer_produksi_overhead_awal: location?.state?.transfer?.filter?.tgl_transfer_produksi_overhead_awal,
      tgl_transfer_produksi_overhead_akhir: location?.state?.transfer?.filter?.tgl_transfer_produksi_overhead_akhir,
      tgl_permintaan_produksi_awal: location?.state?.transfer?.filter?.tgl_permintaan_produksi_awal,
      tgl_permintaan_produksi_akhir: location?.state?.transfer?.filter?.tgl_permintaan_produksi_akhir,
      id_item_buaso: location?.state?.transfer?.filter?.id_item_buaso,
    },
    pagination: {
      page: location?.state?.transfer?.filter?.page ?? "1",
      dataLength: location?.state?.transfer?.filter?.dataLength ?? "10",
      totalPage: location?.state?.transfer?.filter?.totalPage ?? "1",
      dataCount: location?.state?.transfer?.filter?.dataCount ?? "0",
    }
  })
  // const [filterConfig, setFilterConfig] = useState(initialFilter)
  const [dropdown, setDropdown] = useState({
    overhead: [{ value: undefined, label: 'Semua' },]
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    // let query = { page: paginationConfig.page, per_page: paginationConfig.dataLength, q: searchConfig.key }

    // if (filterConfig.isFilter) {
    //   const filterQuery = { ...filterConfig }
    //   delete filterQuery.isFilter

    //   query = Object.assign(query, filterQuery)
    // }

    TransferOverheadProduksiApi.get({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_transfer_produksi_overhead_awal: dataFilter?.filter?.tgl_transfer_produksi_overhead_awal,
      tgl_transfer_produksi_overhead_akhir: dataFilter?.filter?.tgl_transfer_produksi_overhead_akhir,
      tgl_permintaan_produksi_awal: dataFilter?.filter?.tgl_permintaan_produksi_awal,
      tgl_permintaan_produksi_akhir: dataFilter?.filter?.tgl_permintaan_produksi_akhir,
      id_item_buaso: dataFilter?.filter?.id_item_buaso,
    }) //query
      .then(data => {
        setDataTransfer(data?.data?.data ?? [])
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          }
        })
      })
      .catch((err) => {
        setDataTransfer([{}])
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const fetchDropdown = () => {
    TransferOverheadProduksiApi.getDropdown({ tipe: 'overhead' })
      .then((res) => {
        const mapOverhead = res.data.data.map(item => ({ value: item.id_item_buaso, label: item.nama_item }))

        setDropdown((prev) => ({ overhead: prev.overhead.concat(mapOverhead) }))
      })
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }

  // reset filter field
  // const handleResetFilter = () => {
  //   setModalConfig(prev => ({ ...prev, show: false }))
  //   setFilterConfig(initialFilter)
  // }

  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    })
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
  }

  // const checkPaginationHandler = () =>
  //   new Promise((resolve, reject) => {
  //     if (Boolean(paginationFromBrowserState)) {
  //       setPaginationConfig({
  //         ...paginationConfig,
  //         page: paginationFromBrowserState.page,
  //         dataLength: paginationFromBrowserState.dataLength,
  //         totalPage: paginationFromBrowserState.totalPage,
  //         dataCount: paginationFromBrowserState.dataCount,
  //       })

  //       resolve({
  //         page: paginationFromBrowserState.page,
  //         dataLength: paginationFromBrowserState.dataLength,
  //         totalPage: paginationFromBrowserState.totalPage,
  //         dataCount: paginationFromBrowserState.dataCount,
  //       })
  //     }

  //     reject({})
  // })

  useEffect(() => {
    fetchDropdown()

    return () => { setDropdown({ overhead: [] }) }
  }, [])

  useEffect(() => {
    setNavbarTitle("Transfer Overhead Produksi")
    // checkPaginationHandler().finally(() => getInitialData())
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }

    // eslint-disable-next-line
  }, [setNavbarTitle])

  useEffect(() => {
    getInitialData()

    return () => {
      setIsPageLoading(false)
    }
  }, [
    searchConfig.key, 
    dataFilter?.pagination?.page, 
    dataFilter?.pagination?.dataLength, 
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_transfer_produksi_overhead_awal,
    dataFilter?.filter?.tgl_transfer_produksi_overhead_akhir,
    dataFilter?.filter?.tgl_permintaan_produksi_awal,
    dataFilter?.filter?.tgl_permintaan_produksi_akhir,
    dataFilter?.filter?.id_item_buaso,
  ])

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi Transfer Overhead Produksi</ThFixed>
            <ThFixed>Informasi Permintaan Produksi</ThFixed>
            <Th>Item Overhead</Th>
            <Th width={100}>Qty. Transfer</Th>
            <Th width={150}>Harga Satuan</Th>
            <Th width={150}>Total Harga</Th>
            <Th>Keterangan Transfer</Th>
          </Tr>
        </THead>
        <TBody>
          {dataTransfer?.map((val, index) => {
            return (
              <Tr key={index}>
                <Td className="text-center">{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</Td>
                <TdFixed>
                  <ButtonGroup>
                    <ReadButton
                      size="sm"
                      tooltip
                      tooltipText="Detail Transfer Overhead Produksi"
                      className="btn btn-block"
                      onClick={() => setModalConfig({ show: true, type: "detail", data: val })}
                    />
                  </ButtonGroup>
                </TdFixed>
                <Td>
                  <div> {val.tgl_transfer_produksi_overhead ? DateConvert(new Date(val.tgl_transfer_produksi_overhead)).defaultDMY : "-"} </div>
                  <div> {val.no_transfer_produksi_overhead ?? "-"} </div>
                </Td>
                <Td>
                  <div> {val.tgl_permintaan_produksi ? DateConvert(new Date(val.tgl_permintaan_produksi)).defaultDMY : "-"} </div>
                  <div> {val.no_permintaan_produksi ?? "-"} </div>
                </Td>
                <Td>{val.nama_item ?? "-"}</Td>
                <Td className="text-right">
                  {val?.qty_transfer ? ` ${parseFloat(val.qty_transfer ?? 0).toPrecision()} ${val.nama_satuan ?? ""}` : '-'}
                </Td>
                <Td textRight>{val?.harga_satuan_overhead ? RupiahConvert(String(parseInt(val.harga_satuan_overhead || 0))).detail : '-'} </Td>
                <Td textRight>{val?.harga_satuan_overhead ? RupiahConvert(String(parseInt(val.harga_satuan_overhead || 0) * parseFloat(val.qty_transfer || 0))).detail : '-'} </Td>
                <Td> {val.keterangan_transfer ?? "-"} </Td>
              </Tr>
            )
          })}
        </TBody>
      </Table >
    )

    if (!dataTransfer || dataTransfer?.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <small className="font-weight-bold">List Data Transfer Overhead Produksi</small>
        <DataTable />

        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
            }
            dataPage={
              dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                }
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                }
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch
                value={searchConfig.key}
                onChange={onInputSearchChange}
              />
            </Col>
            <FilterButton 
              active={dataFilter?.filter?.active}
              onClick={() => setModalConfig({ show: true, type: 'filter' })}
            />
            {/* <ActionButton
              className="mx-2 mb-2 p1 "
              size="md"
              text={`Filter : ${dataFilter?.active ? 'ON' : 'OFF'}`}
              onClick={() => setModalConfig({ show: true, type: 'filter' })}
            /> */}
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push("/Transaksi/transfer-overhead-produksi/list-permintaan-produksi", {...location?.state, transfer: dataFilter})
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading
        ? (<DataStatus loading={true} text="Memuat data . . ." />)
        : (<PageContent />)
      }

      {/* Modal Detail Transfer Overhead Produksi */}
      {modalConfig.show && modalConfig.type === "detail" && (<ModalDetail modalConfig={modalConfig} setModalConfig={setModalConfig} />)}

      {/* Modal Filter  */}
      {modalConfig.show && modalConfig.type === "filter" && (
        <ModalFilterTransfer
          dropdown={dropdown}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
          // filterConfig={filterConfig}
          // setFilterConfig={setFilterConfig}
          // handleResetFilter={handleResetFilter}
        />
      )}
    </CRUDLayout>
  )
}

export default ListTransferOverheadProduksi