import React from 'react'
import { InfoItemHorizontal, InfoItemVertical } from 'components'
import { DateConvert, RupiahConvert } from 'utilities'

const InfoSectionModal = ({ data, tipe, dataInfo }) => {
  return (
    <div>
      <InfoItemHorizontal label="Tgl. Compliment" text={tipe === "posting" 
        ? dataInfo?.tgl_compliment ? DateConvert(new Date(dataInfo?.tgl_compliment)).detail : "-" 
        : data?.tgl_compliment ? DateConvert(new Date(data?.tgl_compliment)).detail : "-"
      } />
      <InfoItemHorizontal label="No. Compliment" text={tipe === "posting" 
        ? dataInfo?.no_compliment ?? "-" 
        : data?.no_compliment ?? "-"
      } />
      <InfoItemHorizontal label="Kode Barang" text={data?.kode_item ?? "-"} />
      {(tipe === "posting" || tipe === "history" || tipe === "detail") && <InfoItemHorizontal label="Item Barang" text={data?.nama_item ?? "-"} />}
      <InfoItemHorizontal label="Qty. Compliment" text={data?.qty_compliment ? parseFloat(data.qty_compliment) : "-"} />
      <InfoItemHorizontal label="Satuan" text={data?.nama_satuan ?? "-"} />
      <InfoItemHorizontal label="Harga Satuan" text={data?.harga_satuan ? RupiahConvert(parseInt(data?.harga_satuan || 0).toString()).getWithComa : "-"} />
      <hr />
    </div>
  )
}

export default InfoSectionModal