import React, {
    useState
  } from 'react'
  import {
    IoAddOutline,
    IoCheckmarkOutline
  } from 'react-icons/io5'
  import {
    Td,
    Th,
    Table,
    ActionButton,
    DataStatus,
    ThFixed
  } from 'components'
  import {
    RupiahConvert,
    DateConvert
  } from 'utilities'
  import {
    useIsGuest
  } from 'hooks'
  import ModalJurnalBDP from './ModalJurnalBDP'
  
  const TableRealisasiOverhead = ({ dataList, dataHistory, getData }) => {
    const isGuest = useIsGuest()
    const [modalConfig, setModalConfig] = useState({
      show: false,
      data: {}
    })
  
    return (
      <>
        <Table>
          <thead className="bg-light">
            <tr>
              <ThFixed className="py-2 text-center">No.</ThFixed>
              <ThFixed className="p-2 text-center">Informasi Realisasi Produksi</ThFixed>
              <Th className="p-2 text-center" style={{ width: 400 }}>Item Overhead</Th>
              <Th className="p-2 text-center">Satuan</Th>
              <Th className="p-1 text-center" style={{ width: 70 }}>Qty. Realisasi Overhead</Th>
              <Th className="p-1 text-center" style={{ width: 140 }}>Harga Satuan</Th>
              <Th className="p-1 text-center" style={{ width: 140 }}>Jumlah</Th>
              {!isGuest && <ThFixed className="p-0 text-center">Aksi</ThFixed>}
            </tr>
          </thead>
          <tbody>
            {dataList?.length > 0 ? dataList?.map((val, index) => {
              const isInHistory = dataHistory?.find(e => e.id_realisasi_produksi_overhead === val.id_realisasi_produksi_overhead && true)
  
              return (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td>
                    <span className='d-block'>{`${val.tgl_realisasi_produksi ? DateConvert(new Date(val.tgl_realisasi_produksi)).defaultDMY : '-'}`}</span>
                    <span className='d-block'>{`${val.no_realisasi_produksi ?? ''}`}</span>
                  </Td>
                  <Td>{val.nama_item}</Td>
                  <Td>{val.nama_satuan}</Td>
                  <Td className="text-right">{val.qty_realisasi_overhead}</Td>
                  <Td className="text-right">{RupiahConvert(String(parseInt(val?.harga_satuan_overhead ?? 0))).detail}</Td>
                  <Td className="text-right">{RupiahConvert(String(parseInt(val.qty_realisasi_overhead) * parseInt(val.harga_satuan_overhead ?? 0))).detail}</Td>
                  {!isGuest && <Td className="text-center">
                    {isInHistory
                      ? (
                        <ActionButton
                          disable
                          size="sm"
                          className="bg-success" >
                          <IoCheckmarkOutline />
                        </ActionButton>
                      )
                      : (
                        <ActionButton
                          size="sm"
                          style={{ backgroundColor: '#1956b9' }}
                          onClick={() => setModalConfig({ show: true, data: val })}
                          disable={Boolean(isInHistory)}
                        >
                          <IoAddOutline />
                        </ActionButton>
                      )
                    }
                  </Td>}
                </tr>
              )
            })
              : (
                <tr>
                  <Td colSpan={9}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </tr>
              )
            }
          </tbody>
        </Table>
  
        {modalConfig.show && <ModalJurnalBDP
          dataList={dataList}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          getData={getData}
        />}
      </>
    )
  }
  
  export default TableRealisasiOverhead