import Axios from "axios"

const { REACT_APP_API_BASE_URL } = process.env //Base url dari file .env

// Buat instance axios
const Services = Axios.create({
  baseURL: REACT_APP_API_BASE_URL,
})

// Cek token pada session storage
Services.interceptors.request.use((config) => {
  const token = "Bearer " + sessionStorage.getItem("token")

  config.headers = {
    Authorization: token,
    "Content-Type": "application/json",
  }

  return config
})

export default Services
