import React, { useState } from "react"
import { IoEyeOutline } from "react-icons/io5"
import { Td, Th, ThFixed, Table, ActionButton, DataStatus } from "components"
import { RupiahConvert, DateConvert } from "utilities"


import { ModalDetailJurnalBDP } from '../../../components'

const TableHistoryOverhead = ({ dataHistory }) => {
  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {},
  })

  const TableBodyDetail = () => {
    return (
      <tbody>
        {dataHistory?.length > 0 ? (
          dataHistory?.map((val, index) => {
            return (
              <tr key={index}>
                <Td className="text-center">{index + 1}</Td>
                <Td>
                  <span className='d-block'>{`${val.tgl_bdp_overhead ? DateConvert(new Date(val.tgl_bdp_overhead)).defaultDMY : '-'}`}</span>
                  <span className='d-block'>{`${val.no_bdp_overhead ?? ''}`}</span>
                </Td>
                <Td>{val.nama_item}</Td>
                <Td className="text-right">{RupiahConvert(String(String(parseInt(val.qty_realisasi_overhead ?? 0) * parseInt(val.harga_satuan_overhead ?? 0)))).detail}</Td>
                <Td>
                  {val.nama_akun_debit && <span className='d-block'>{`D: ${val.nama_akun_debit}`}</span>}
                  {val.nama_akun_kredit && <span className='d-block'>{` K: ${val.nama_akun_kredit ?? ''}`}</span>}
                </Td>
                <Td>{val.keterangan_jurnal}</Td>
                <Td className="text-center">
                  <ActionButton
                    size="sm"
                    variant="primary"
                    onClick={() => setModalConfig({ show: true, data: val })}
                  >
                    <IoEyeOutline />
                  </ActionButton>
                </Td>
              </tr>
            )
          })
        ) : (
          <tr>
            <Td colSpan={9}>
              <DataStatus text="Tidak ada data" />
            </Td>
          </tr>
        )}
      </tbody>
    )
  }

  return (
    <>
      <Table>
        <thead className="bg-light">
          <tr>
            <ThFixed className="py-2 text-center">No.</ThFixed>
            <ThFixed className="p-2 text-center">Informasi BDP Overhead</ThFixed>
            <Th className="p-2 text-center" style={{ width: 400 }}> Item Overhead </Th>
            <Th className="p-2 text-center">Jumlah</Th>
            <Th className="p-2 text-center" style={{ minWidth: 200 }}> Akun COA </Th>
            <Th className="p-2 text-center">Keterangan Jurnal</Th>
            {<ThFixed className="p-0 text-center">Aksi</ThFixed>}
          </tr>
        </thead>
        <TableBodyDetail />
      </Table>

      {modalConfig.show && <ModalDetailJurnalBDP
        BDPType="Overhead"
        modalConfig={modalConfig}
        setModalConfig={setModalConfig}
      />
      }
    </>
  )
}

export default TableHistoryOverhead
