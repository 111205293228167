// Dashboard
import Dashboard from 'pages/Dashboard'
// MASTER
import { 
  ItemOverhead, 
  KelompokOverhead,
  HPS
  // HargaPerkiraanSendiri 
} from "pages/Master"
import {
  // Transfer Overhead Produksi
  ListTransferOverheadProduksi,
  ListPermintaanOverheadProduksi,
  TambahTransferOverheadProduksi,

  // Harga Satuan Rata-Rata
  HargaSatuanRataRataBahanList,

  // BDP Produksi
  ListBDPProduksi,
  ListRealisasiProduksiBDP,
  DataBDPProduksi,

  // HPP
  ListHPP,
  ListProduksi,
  TambahHPP,
  DetailHPP,
  DetailProduksi,

  // Posting Compliment
  PostingComplimentList,
  ComplimentReadyToPosting,
  PostingCompliment
} from "pages/Transaksi"

/* 
  MENU REQUIREMENTS
    > EXACT = OPTIONAL (TRUE/FALSE)
    > PATH  = REQUIRED
    > PAGE  = REQUIRED
    > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
*/

export default [
  // Dashboard
  {
    exact: true,
    path: "/",
    page: Dashboard,
    hak: ["SUPA", "COC"],
  },
  // MASTER
  {
    exact: true,
    path: "/master/item-overhead",
    page: ItemOverhead,
    hak: ["SUPA", "COC_REG_OVH"],
  },
  {
    exact: true,
    path: "/master/kelompok-overhead",
    page: KelompokOverhead,
    hak: ["SUPA", "COC_MAS_KLO"],
  },
  {
    // text: "Harga Perkiraan Sendiri",
    exact: true,
    path: "/master/harga-satuan-rata-rata",
    page: HPS,
    hak: ["SUPA", "COC_MAS_HPS"],
  },

  
  // {
  //   text: "Harga Perkiraan Sendiri",
  //   path: "/master/harga-perkiraan-sendiri",
  //   page: HargaPerkiraanSendiri,
  //   hak: ["SUPA", "COC_MAS_HPS"],
  // },
  // TRANSAKSI
  // Transfer Produksi Overhead
  {
    exact: true,
    path: "/transaksi/transfer-overhead-produksi",
    page: ListTransferOverheadProduksi,
    hak: ["SUPA", "COC_TRN_TPO"],
  },
  {
    exact: true,
    path: "/transaksi/transfer-overhead-produksi/list-permintaan-produksi",
    page: ListPermintaanOverheadProduksi,
    hak: ["SUPA", "COC_TRN_TPO"],
  },
  {
    exact: true,
    path: "/transaksi/transfer-overhead-produksi/tambah/:id_permintaan_produksi",
    page: TambahTransferOverheadProduksi,
    hak: ["SUPA", "COC_TRN_TPO"],
  },
  // Harga Satuan Rata-Rata
  {
    exact: true,
    path: "/transaksi/harga-satuan-rata-rata-bahan",
    page: HargaSatuanRataRataBahanList,
    hak: ["SUPA", "COC_TRN_HSB"],
  },
  // BDP Produksi
  {
    exact: true,
    path: "/transaksi/bdp-produksi/",
    page: ListBDPProduksi,
    hak: ["SUPA", "COC_TRN_BDP"]
  },
  {
    exact: true,
    path: "/transaksi/bdp-produksi/list-realisasi-produksi",
    page: ListRealisasiProduksiBDP,
    hak: ["SUPA", "COC_TRN_BDP"]
  },
  {
    exact: true,
    path: "/transaksi/bdp-produksi/data/:id_realisasi_produksi",
    page: DataBDPProduksi,
    hak: ["SUPA", "COC_TRN_BDP"]
  },
  {
    exact: true,
    path: "/transaksi/hpp",
    page: ListHPP,
    hak: ["SUPA", "COC_TRN_HPP"],
  },
  {
    exact: true,
    path: "/transaksi/hpp/list-produksi",
    page: ListProduksi,
    hak: ["SUPA", "COC_TRN_HPP"],
  },
  {
    exact: true,
    path: "/transaksi/hpp/list-produksi/detail/:id",
    page: DetailProduksi,
    hak: ["SUPA", "COC_TRN_HPP"],
  },
  {
    exact: true,
    path: "/transaksi/hpp/tambah/:id",
    page: TambahHPP,
    hak: ["SUPA", "COC_TRN_HPP"],
  },
  {
    exact: true,
    path: "/transaksi/hpp/detail/:id",
    page: DetailHPP,
    hak: ["SUPA", "COC_TRN_HPP"],
  },
  // Posting Compliment
  {
    exact: true,
    path: "/transaksi/posting-compliment",
    page: PostingComplimentList,
    hak: ["SUPA", "COC_TRN_PCMP"],
  },
  {
    exact: true,
    path: "/transaksi/posting-compliment/list-compliment-siap-di-posting",
    page: ComplimentReadyToPosting,
    hak: ["SUPA", "COC_TRN_PCMP"],
  },
  {
    exact: true,
    path: "/transaksi/posting-compliment/create/:id_compliment",
    page: PostingCompliment,
    hak: ["SUPA", "COC_TRN_PCMP"],
  },
]