import { DatePicker, SelectSearch, FilterModal } from "components";
import { DateConvert } from "utilities";
import { useFormik } from "formik";

const ModalFilterHPP = ({
  SESSION_KEY,
  dropdown,
  modalConfig,
  setModalConfig,
  data,
  setData,
}) => {
  console.log("test", dropdown?.item_produksi)
  const filter = data?.filter;
  const formInitialValues = {
    tgl_hpp_mulai: filter?.tgl_hpp_mulai,
    tgl_hpp_selesai: filter?.tgl_hpp_selesai,
    tgl_job_order_mulai: filter?.tgl_job_order_mulai,
    tgl_job_order_selesai: filter?.tgl_job_order_selesai,
    id_barang: filter?.id_barang,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;
    if (checkActive) {
      setData({
        filter: { ...values, active: true },
        pagination: { ...data.pagination, page: 1 },
      });
    } else {
      setData({ ...data, filter: { ...values, active: false } });
    }
    setModalConfig(false);
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: formSubmitHandler,
  });

  const { values, setFieldValue, setValues, handleSubmit } = formik;

  const onChangeTglHPP = (dates) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_hpp_mulai: startDate,
      tgl_hpp_selesai: endDate,
    });
  };
  const onChangeTglProduksi = (dates) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_job_order_mulai: startDate,
      tgl_job_order_selesai: endDate,
    });
  };
  const onResetButtonClick = () => {
    // DELETE FILTER & PAGINATION DI SESSION STORAGE
    sessionStorage.removeItem(SESSION_KEY.FILTER);
    sessionStorage.removeItem(SESSION_KEY.PAGINATION);

    setData((prev) => ({
      ...prev,
      filter: {
        tgl_hpp_mulai: undefined,
        tgl_hpp_selesai: undefined,
        tgl_job_order_mulai: undefined,
        tgl_job_order_selesai: undefined,
        id_barang: undefined,
      },
      pagination: {
        ...prev.pagination,
        page: 1,
        per_page: 10,
      },
    }));

    setModalConfig(false);
  };

  return (
    <FilterModal
      show={modalConfig?.show}
      setShow={setModalConfig}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}>
      <DatePicker
        selectsRange
        label="Tgl. Harga Pokok Produksi"
        placeholderText="Pilih tanggal harga pokok produksi"
        startDate={values?.tgl_hpp_mulai ? new Date(values?.tgl_hpp_mulai) : ""}
        endDate={values?.tgl_hpp_selesai ? new Date(values?.tgl_hpp_selesai) : ""}
        onChange={(dates) => onChangeTglHPP(dates)}
        monthsShown={2}
        dateFormat="dd/MM/yyyy"
      />
      <DatePicker
        selectsRange
        label="Tgl. Produksi"
        placeholderText="Pilih tanggal produksi"
        startDate={values?.tgl_job_order_mulai ? new Date(values?.tgl_job_order_mulai) : ""}
        endDate={values?.tgl_job_order_selesai ? new Date(values?.tgl_job_order_selesai) : ""}
        onChange={(dates) => onChangeTglProduksi(dates)}
        monthsShown={2}
        dateFormat="dd/MM/yyyy"
      />
      <SelectSearch
        key={values?.id_barang}
        label="Item Produksi"
        placeholder="Pilih item produksi"
        defaultValue={dropdown?.item_produksi?.find((item) => item.value === values.id_barang)}
        option={dropdown?.item_produksi ?? []}
        onChange={(val) => setFieldValue("id_barang", val.value)}
      />
    </FilterModal>
  );
};

export default ModalFilterHPP;
