import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Row, Col, ButtonGroup } from "react-bootstrap"
import axios from "axios"
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  ThFixed,
  TdFixed,
  Th,
  Td,
  BackButton,
  FilterButton,
  Tr,
  THead,
  TBody
} from "components"
import { TableNumber, DateConvert } from "utilities"
import { IoAddOutline } from "react-icons/io5"
import { PostingComplimentApi } from "api"
import { ModalFilterPostingCompliment } from "./Section"
import Axios from "axios"

const ItemCollapse = ({ data }) => {
  const [isReadMoreClick, setisReadMoreClick] = useState(false)
  const Item = ({ val, index }) => (<li key={index}>{val.nama_item} {val.qty_produksi ? `${parseFloat(val?.qty_produksi)} ${val?.kode_satuan}` : ''}</li>)
  return (
    <>
      <ul className="pl-3 mb-1" style={{ fontSize: "13px" }}>
        {data.map((val, index) => {
          if (isReadMoreClick) {
            return <Item val={val} key={index} />
          } else {
            return index < 2 && <Item val={val} key={index} />
          }
        })}
      </ul>

      {data.length > 2 &&
        <div
          className="text-primary"
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => setisReadMoreClick((prev) => !prev)}
        >
          {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
        </div>}
    </>
  )
}

const ComplimentReadyToPosting = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [dataCompliment, setDataCompliment] = useState([])
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  const [modalConfig, setModalConfig] = useState({ show: false, type: "filter", data: {} })
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.posting_compliment?.filter?.active,
      tgl_compliment_mulai: location?.state?.posting_compliment?.filter?.tgl_compliment_mulai,
      tgl_compliment_selesai: location?.state?.posting_compliment?.filter?.tgl_compliment_selesai,
      id_customer: location?.state?.posting_compliment?.filter?.id_customer,
      id_petugas: location?.state?.posting_compliment?.filter?.id_petugas,
      id_barang: location?.state?.posting_compliment?.filter?.id_barang,
    },
    pagination: {
      page: location?.state?.posting_compliment?.filter?.page ?? "1",
      dataLength: location?.state?.posting_compliment?.filter?.dataLength ?? "10",
      totalPage: location?.state?.posting_compliment?.filter?.totalPage ?? "1",
      dataCount: location?.state?.posting_compliment?.filter?.dataCount ?? "0",
    }
  })
  const [dropdown, setDropdown] = useState({
    customer: [{ value: undefined, label: 'Semua' }],
    petugas: [{ value: undefined, label: 'Semua' }],
    nama_item: [{ value: undefined, label: 'Semua' }],
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    PostingComplimentApi.getPreFormPage({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_compliment_mulai: dataFilter?.filter?.tgl_compliment_mulai,
      tgl_compliment_selesai: dataFilter?.filter?.tgl_compliment_selesai,
      id_customer: dataFilter?.filter?.id_customer,
      id_petugas: dataFilter?.filter?.id_petugas,
      id_barang: dataFilter?.filter?.id_barang,
    })
      .then(data => {
        setDataCompliment(data?.data?.data ?? [])
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          }
        })
      })
      .catch((err) => {
        setDataCompliment([])
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      })
      .finally(() => setIsPageLoading(false))
  }
  const fetchDropdown = () => {
    Axios.all([
      PostingComplimentApi.dropdownCustomer(),
      PostingComplimentApi.dropdownPetugas(),
      PostingComplimentApi.dropdownBarang()
    ])
      .then(Axios.spread((customer, petugas, barang) => {
        const mapCustomer = customer?.data?.data ? customer.data.data.map(val => ({ value: val.id_customer, label: val.nama_customer })) : []
        const mapPetugas = petugas?.data?.data ? petugas.data.data.map(val => ({ value: val.id_karyawan, label: val.nama_karyawan })) : []
        const mapBarang = barang?.data?.data ? barang.data.data.map(val => ({ value: val.id_item_buaso, label: val.nama_item })) : []

        setDropdown((prev) => ({ 
          customer: prev.customer.concat(mapCustomer),
          petugas: prev.petugas.concat(mapPetugas),
          nama_item: prev.nama_item.concat(mapBarang)
        }))
      }))
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }
  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    })
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
  }

  useEffect(() => { fetchDropdown() }, [])
  useEffect(() => {
    setNavbarTitle("Posting Compliment")
    getInitialData()
    checkAlert()

    return () => { setIsPageLoading(false) }
  }, [setNavbarTitle])
  useEffect(() => {
    getInitialData()

    return () => { setIsPageLoading(false) }
  }, [
    searchConfig.key, 
    dataFilter?.pagination?.page, 
    dataFilter?.pagination?.dataLength, 
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_compliment_mulai,
    dataFilter?.filter?.tgl_compliment_selesai,
    dataFilter?.filter?.id_customer,
    dataFilter?.filter?.id_petugas,
    dataFilter?.filter?.id_barang,
  ])

  const PageContent = () => {
    const ItemBarangCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false)
  
      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              if (isReadMoreClick) {
                return <li key={index} index={index}>{`${brg.nama_item ?? "-"} ${brg.qty_compliment ? parseFloat(brg.qty_compliment) : ""} ${brg.nama_satuan ? brg.nama_satuan : ""}`?? "-"}</li>
              } else {
                return index <= 1 && <li key={index} index={index}>{`${brg.nama_item ?? "-"} ${brg.qty_compliment ? parseFloat(brg.qty_compliment) : ""} ${brg.nama_satuan ? brg.nama_satuan : ""}`?? "-"}</li>
              }
            })}
          </ul>
          {data.length > 2 ? (
            <div
              className="text-primary"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => { setisReadMoreClick((prev) => !prev)}}>
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          ): ("")}
        </>
      )
    }
    const DataTable = () => (
      <Table>
        <THead>
          <Tr className="text-center">
            <ThFixed>No.</ThFixed>
            <ThFixed>Informasi Compliment</ThFixed>
            <Th style={{ minWidth: "300px" }}>Customer</Th>
            <Th style={{ minWidth: "200px" }}>Petugas Pemberi Compliment</Th>
            <Th style={{ minWidth: "300px" }}>Item Barang</Th>
            <Th style={{ minWidth: "300px" }}>Keterangan Transfer</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {dataCompliment?.map((val, index) => {
            return (
              <Tr key={index}>
                <Td className="text-center">{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</Td>
                <TdFixed>
                  <div>{val.tgl_compliment ? DateConvert(new Date(val.tgl_compliment)).defaultDMY : "-"}</div>
                  <div>{val.no_compliment ?? "-"}</div>
                </TdFixed>
                <Td>{val.customer ?? "-"}</Td>
                <Td> {val.petugas ?? "-"} </Td>
                <Td>{val.detail ? val.detail.length > 0 ? <ItemBarangCollapse data={val.detail} /> : "-" : "-"}</Td>
                <Td>{val.keterangan ?? "-"}</Td>
                <TdFixed>
                  <ButtonGroup>
                    <ActionButton
                      size="sm"
                      onClick={() => history.push("/transaksi/posting-compliment/create/" + val.id_compliment, {
                        id_compliment: val.id_compliment,
                        ...location?.state, readyToPosting: dataFilter
                      })}
                    >
                      <IoAddOutline />
                    </ActionButton>
                  </ButtonGroup>
                </TdFixed>
              </Tr>
            )
          })}
        </TBody>
      </Table >
    )

    if (!dataCompliment || dataCompliment?.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }
    return (
      <>
        <small className="font-weight-bold">List Data Compliment Yang Siap Diposting</small>
        <DataTable />

        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
            }
            dataPage={
              dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                }
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                }
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            </Col>
            <FilterButton 
              active={dataFilter?.filter?.active}
              onClick={() => setModalConfig({ show: true, type: 'filter' })}
            />
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton className="mr-2"
            onClick={() => history.push("/transaksi/posting-compliment", { ...location?.state, posting_compliment: dataFilter})}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })
        }
      />

      {/* content */}
      {isPageLoading
        ? (<DataStatus loading={true} text="Memuat data . . ." />)
        : (<PageContent />)
      }
      {/* ModalFilter */}
      {modalConfig.show && modalConfig.type === "filter" && (
        <ModalFilterPostingCompliment
          dropdown={dropdown}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

    </CRUDLayout>
  )
}

export default ComplimentReadyToPosting