// map response data BDP from API
export const mapBDP = (data) =>
  data.map((item) => ({
    id_bdp:
      item.id_bdp_bahan ??
      item.id_bdp_upah ??
      item.id_bdp_alat_mesin ??
      item.id_bdp_subkon ??
      item.id_bdp_overhead,
    tgl_bdp:
      item.tgl_bdp_bahan ??
      item.tgl_bdp_upah ??
      item.tgl_bdp_alat_mesin ??
      item.tgl_bdp_subkon ??
      item.tgl_bdp_overhead,
    no_bdp:
      item.no_bdp_bahan ??
      item.no_bdp_upah ??
      item.no_bdp_alat_mesin ??
      item.no_bdp_subkon ??
      item.no_bdp_overhead,
    nama_item: item.nama_item,
    qty_realisasi:
      item.qty_realisasi_bahan ??
      item.qty_realisasi_upah ??
      item.qty_realisasi_alat_mesin ??
      item.qty_realisasi_subkon ??
      item.qty_realisasi_overhead,
    harga_satuan:
      item.harga_satuan_rata_rata_pakai ??
      item.harga_satuan_upah ??
      item.harga_satuan_alat_mesin ??
      item.harga_satuan_subkon ??
      item.harga_satuan_overhead,
    keterangan_jurnal: item.keterangan_jurnal,
    nomor_akun_debet: item.nomor_akun_debet,
    nomor_akun_kredit: item.nomor_akun_kredit,
    nama_akun_debit: item.nama_akun_debit,
    nama_akun_kredit: item.nama_akun_kredit,
    nama_satuan: item.nama_satuan,
  }));

// handle calculate BDP table
export const grandTotalBDP = (dataBDP) => {
  const totalBahan = dataBDP.bahan.reduce(
    (total, item) => total + parseInt(item.harga_satuan ?? 0) * parseFloat(item.qty_realisasi ?? 0),
    0
  );
  const totalUpah = dataBDP.upah.reduce(
    (total, item) => total + parseInt(item.harga_satuan ?? 0) * parseFloat(item.qty_realisasi ?? 0),
    0
  );
  const totalAlatMesin = dataBDP.alat_mesin.reduce(
    (total, item) => total + parseInt(item.harga_satuan ?? 0) * parseFloat(item.qty_realisasi ?? 0),
    0
  );
  const totalSubcont = dataBDP.subcont.reduce(
    (total, item) => total + parseInt(item.harga_satuan ?? 0) * parseFloat(item.qty_realisasi ?? 0),
    0
  );
  const totalOverhead = dataBDP.overhead.reduce(
    (total, item) => total + parseInt(item.harga_satuan ?? 0) * parseFloat(item.qty_realisasi ?? 0),
    0
  );

  const totalBDP = totalBahan + totalUpah + totalAlatMesin + totalSubcont + totalOverhead;

  return {
    totalBahan,
    totalUpah,
    totalAlatMesin,
    totalSubcont,
    totalOverhead,
    totalBDP,
  };
};
