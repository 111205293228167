import { Row, Col, Modal, Button } from 'react-bootstrap'
import { InfoItemVertical } from 'components'
import { InfoSectionModal } from '../InfoSection'
import { DateConvert, RupiahConvert } from 'utilities'

const ModalDetail = ({ modalConfig, setModalConfig }) => {
  const data = modalConfig.data

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({ show: false, type: 'detail', data: {} })}
    >
      <Modal.Header closeButton>
        <b>Detail Posting Compliment</b>
      </Modal.Header>
      <Modal.Body>
        <InfoSectionModal data={modalConfig.data} tipe={modalConfig.type} />
        <Row>
          <Col>
            <InfoItemVertical
              label="Tgl. Posting Compliment"
              text={data?.tgl_posting_compliment ? DateConvert(new Date(data?.tgl_posting_compliment)).detail : "-"} />
          </Col>
          <Col>
            <InfoItemVertical
              label="No. Posting Compliment"
              text={data?.no_posting_compliment ?? "-"} />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical
              label="Jumlah"
              text={data?.jumlah ? RupiahConvert(parseInt(data?.jumlah || 0).toString()).getWithComa : "Rp.0"} 
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical
              label="COA Debet"
              text={data?.nama_akun_debet ? `${data?.nomor_akun_debet ?? ""} - ${data?.nama_akun_debet ?? ""}` : "-"} />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical
              label="COA Kredit"
              text={data?.nama_akun_kredit ? `${data?.nomor_akun_kredit ?? ""} - ${data?.nama_akun_kredit ?? ""}` : "-"} />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical
              label="Keterangan"
              text={data?.keterangan_jurnal ?? "-"} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={() => setModalConfig({ show: false, type: 'detail', data: {} })}
        >
          TUTUP
        </Button>
      </Modal.Footer>
    </Modal >
  )
}

export default ModalDetail