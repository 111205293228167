import { Table, THead, TBody, ThFixed, TdFixed, Tr, Th, Td, DataStatus } from "components";
import { DateConvert, RupiahConvert } from "utilities";

const TabItem = ({ data = [], type, grandTotal }) => {
  console.log("total", grandTotal)
  const TYPE = {
    bahan: "Bahan",
    upah: "Upah",
    alat_mesin: "Alat & Mesin",
    subkon: "Subkon",
    overhead: "Overhead",
  };
  return (
    <div className="p-1 bg-white">
      {/* Table Item */}
      <div>
        <b>List Data BDP {TYPE[type]}</b>
      </div>
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th style={{ width: 100 }} className="p-1">Informasi BDP Produksi</Th>
            <Th style={{ minWidth: 150 }} className="p-1">Item {TYPE[type]}</Th>
            <Th style={{ width: 100 }} className="p-1">Qty.</Th>
            <Th style={{ width: 100 }} className="p-1">Harga Satuan</Th>
            <Th style={{ width: 100 }} className="p-1">Jumlah</Th>
            <Th style={{ minWidth: 80 }} className="p-1">Akun COA</Th>
            <Th style={{ minWidth: 150 }} className="p-1">Keterangan Jurnal</Th>
          </Tr>
        </THead>
        <TBody>
          {data && data?.length > 0 ? (
            data.map((e, i) => (
              <Tr key={i}>
                <TdFixed>{i + 1}</TdFixed>
                <TdFixed>
                  <div>{DateConvert(new Date(e.tgl_bdp)).defaultDMY}</div>
                  <div>{e.no_bdp}</div>
                </TdFixed>
                <Td>{e?.nama_item ?? "-"}</Td>
                <Td textRight>{e?.qty_realisasi ? parseFloat(e.qty_realisasi) : "-"} {e?.nama_satuan ?? ""}</Td>
                <Td textRight>{RupiahConvert(String(parseFloat(e.harga_satuan ?? 0))).getWithComa}</Td>
                <Td textRight>{e.harga_satuan ? RupiahConvert(String(parseFloat(e.harga_satuan ?? 0) * parseFloat(e.qty_realisasi ?? 0))).getWithComa : "Rp.0"}</Td>
                <Td>
                  <div>{`D: ${e.nomor_akun_debet} - ${e.nama_akun_debit}`}</div>
                  <div>{`K: ${e.nomor_akun_kredit} - ${e.nama_akun_kredit}`}</div>
                </Td>
                <TdFixed>{e.keterangan_jurnal ?? "-"}</TdFixed>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={11}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
          {data && data?.length > 0 
            ? <Tr>
                <td className="p-2 align-middle text-right" style={{ fontSize : 14 }} colSpan={5}><b>Total</b></td>
                {/* <Td>{data?.subtotal ? RupiahConvert(String(parseFloat(data?.subtotal) ?? 0)).getWithComa : "Rp.0"}</Td> */}
                <Td textRight>{RupiahConvert(String(parseFloat(grandTotal ?? 0))).getWithComa}</Td>
                <Td></Td>
                <Td></Td>
              </Tr>
            : ""
          }
        </TBody>
      </Table>
    </div>
  );
};

export default TabItem;
