import { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { DatePicker, SelectSearch } from 'components'
import { DateConvert } from 'utilities'
import { Formik } from 'formik'

const ModalFilterPostingCompliment = ({ dropdown, modalConfig, setModalConfig, data, setData }) => {
  const formInitialValues = {
    tgl_compliment_mulai: data?.filter?.tgl_compliment_mulai,
    tgl_compliment_selesai: data?.filter?.tgl_compliment_selesai,
    id_customer: data?.filter?.id_customer,
    id_petugas: data?.filter?.id_petugas,
    id_barang: data?.filter?.id_barang,
  }
  const formSubmitHandler = (values) => {
      const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

      if (checkActive) {
        setData({ 
            filter: {
                ...values,
                active: true
            },
            pagination: {
                ...data.pagination,
                page: 1
            }
        })
      } else { 
          setData({ 
              ...data,
              filter: {
                  ...values,
                  active: false
              }
          })
      }

      setModalConfig(prev => ({...prev, show: false}))
  }

  const onChangeTglComplimet = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_compliment_mulai: startDate,
        tgl_compliment_selesai: endDate,
    })
  }
  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_compliment_mulai: undefined,
      tgl_compliment_selesai: undefined,
      id_customer: undefined,
      id_petugas: undefined,
      id_barang: undefined,
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      
      {({values, handleSubmit, setFieldValue, setValues}) => (
        <Modal
          show={modalConfig.show && modalConfig.type === 'filter'}
          onHide={() => setModalConfig(prev => ({ ...prev, show: false }))}
        >
          <Modal.Header closeButton>
            <b>Filter Data</b>
          </Modal.Header>
          <Modal.Body>
            <DatePicker
              selectsRange
              label="Tgl. Compliment"
              placeholderText="Pilih tanggal compliment"
              dateFormat="dd/MM/yyyy"
              startDate={values.tgl_compliment_mulai ? new Date(values.tgl_compliment_mulai) : ''}
              endDate={values.tgl_compliment_selesai ? new Date(values.tgl_compliment_selesai) : ''}
              onChange={(dates) => onChangeTglComplimet(dates, values, setValues)}
              monthsShown={2}
            />
            <SelectSearch
              key={values.id_customer}
              label="Customer"
              placeholder="Pilih customer"
              defaultValue={dropdown?.customer?.find(item => item.value === values.id_customer)}
              option={dropdown.customer}
              onChange={val => setFieldValue('id_customer', val.value)}
            />
            <SelectSearch
              key={values.id_petugas}
              label="Petugas Pemberi Compliment"
              placeholder="Pilih petugas pemberi compliment"
              defaultValue={dropdown?.petugas?.find(item => item.value === values.id_petugas)}
              option={dropdown.petugas}
              onChange={val => setFieldValue('id_petugas', val.value)}
            />
            <SelectSearch
              key={values.id_barang}
              label="Item Barang"
              placeholder="Pilih nama barang"
              defaultValue={dropdown?.nama_item?.find(item => item.value === values.id_barang)}
              option={dropdown.nama_item}
              onChange={val => setFieldValue('id_barang', val.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              onClick={() => onResetButtonClick(values, setValues)}
            >
              RESET
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={handleSubmit}
            >
              FILTER
            </Button>
          </Modal.Footer>
        </Modal >
      )}
    </Formik>
  )
}

export default ModalFilterPostingCompliment