import React, {
    useState,
    useEffect
  } from 'react'
  import { 
    useHistory,
  } from 'react-router-dom'
  import {
    Row,
    Col
  } from 'react-bootstrap'
  import _, {
    debounce
  } from 'lodash'
  import {
    CRUDLayout,
    InputSearch,
    Alert,
    Table,
    THead,
    TBody,
    ThFixed,
    TdFixed,
    Tr,
    Th,
    Td,
    ReadButton,
    UpdateButton,
    DataStatus,
    Pagination,
    ActionButton,
    CreateButton,
    SelectMonth,
    Select
  } from 'components'
  import {
    DateConvert,
    RupiahConvert,
    TableNumber
  } from 'utilities'
  import { HargaSatuanRataRataPakaiApi } from 'api'
  import { ModalPosting } from './Comps'
  
  export const HargaSatuanRataRataBahanList = ({setNavbarTitle}) => {
    const today = new Date()
    const history = useHistory()
    const [data, setData] = useState([])
    const [search, setSearch] = useState(undefined)
    const [tetes, setTetes] = useState("")
    const [fetchingStatus, setFetchingStatus] = useState({
      loading: true,
      error: true
    })
    const [alert, setAlert] = useState({
      show: false,
      variant: 'primary',
      text: ''
    })
    const [pagination, setPagination] = useState({
      page: 1,
      dataLength: 10,
      totalPage: 1,
      dataCount: 0,
    })
    const [modal, setModal] = useState({
      show: false,
    })
    const [filter, setFilter] = useState({
      month: String(today.getMonth() + 1).length > 1 ? today.getMonth() + 1 : `0${today.getMonth() + 1}`, 
      year: today.getFullYear()
    })
  
    const getData = () => {
      setData([])
      setFetchingStatus({
        loading: true,
        error: true
      })
  
      HargaSatuanRataRataPakaiApi.page({
        page: pagination.page,
        per_page: pagination.dataLength,
        q: search,
        tanggal: `${filter.year}-${filter.month}-01`
      })
        .then((res) => {
          setData(res?.data?.data ?? [])
          setPagination({
            ...pagination,
            dataCount: res?.data?.data_count,
            totalPage: res?.data?.total_page,
          })
          setFetchingStatus({
            loading: false,
            error: false
          })
        })
        .catch(() => {
          setData([])
          setFetchingStatus({
            loading: false,
            error: true
          })
        })
    }
  
    const searchHandler = (e) => {
      setSearch(e.target.value)
    }
  
    useEffect(() => {
      setNavbarTitle('Harga Satuan Rata-Rata Bahan')
      setAlert({
        show: !!history?.location?.state?.alert?.show,
        variant: history?.location?.state?.alert?.variant,
        text: history?.location?.state?.alert?.text,
      })
    }, [])
  
    useEffect(() => {
      getData()
    }, [search,  pagination.page, pagination.dataLength, filter.month, filter.year])
  
    return (
      <CRUDLayout>
  
        {/* Head */}
        <CRUDLayout.Head>
          <CRUDLayout.HeadSearchSection>
            <div className="d-flex mb-0">
              <InputSearch 
                onChange={debounce(searchHandler, 800)}
              />
            </div>
          </CRUDLayout.HeadSearchSection>
          <CRUDLayout.HeadButtonSection>
            <div className="d-flex justify-content-end">
              <SelectMonth
                noMargin
                size="md"
                style={{ width: 150 }}
                defaultValue={filter.month}
                onChange={(val) => {
                  setFilter({...filter, month: val.target.value})
                  setPagination({
                    ...pagination,
                    page: 1,
                  })
                }}
              />
              <Select
                name="tahun"
                size="md"
                style={{ width: 100 }}
                wrapperClassName="mx-2"
                defaultValue={filter.year}
                onChange={(val) => {
                  setFilter({...filter, year: val.target.value})
                  setPagination({
                    ...pagination,
                    page: 1
                  })
                }}
              >
                {_.range(today.getFullYear(), 1999).map((e) => (
                  <option value={e}>{e}</option>
                ))}
              </Select>
              <div>
                <ActionButton 
                  text="Posting"
                  className="px-4"
                  onClick={() => setModal({show: true})}
                />
              </div>
            </div>
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>
  
        {/* Alert */}
        <div className="mt-2">
          <Alert 
            show={alert.show}
            variant={alert.variant}
            text={alert.text}
          />
        </div>
  
        {/* Content */}
        {fetchingStatus.loading || fetchingStatus.error
          ? <DataStatus 
              loading={fetchingStatus.loading}
              text={fetchingStatus.loading ? 'Memuat . . .' : 'Data gagal dimuat'}
            />
          : <>
              <div className="p-1">
                <b>List Data Harga Satuan Rata-Rata Bahan</b>
              </div>
              <Table>
                <THead>
                  <Tr>
                    <ThFixed>No.</ThFixed>
                    <Th style={{width: 100,}} className="p-1">Kode Item Bahan</Th>
                    <Th style={{minWidth: 300,}} className="p-1">Item Bahan</Th>
                    <Th style={{width: 150,}} className="p-1">Satuan</Th>
                    <Th style={{width: 150,}} className="p-1">Kelompok Bahan</Th>
                    <Th style={{width: 150,}} className="p-1">Jenis Bahan</Th>
                    <Th style={{width: 150,}} className="p-1">Harga Satuan Rata-Rata Bahan</Th>
                  </Tr>
                </THead>
                <TBody>
                  {data && data?.length > 0
                    ? data.map((e, i) => (
                        <Tr key={i}>
                          <TdFixed>{TableNumber(pagination.page, pagination.dataLength, i)}</TdFixed>
                          <Td>{e?.kode_item ?? '-'}</Td>
                          <Td>{e?.nama_item ?? '-'}</Td>
                          <Td>{e?.nama_satuan ?? '-'}</Td>
                          <Td>{e?.nama_kelompok ?? '-'}</Td>
                          <Td>{e?.nama_jenis ?? '-'}</Td>
                          <Td textRight>{e?.harga_satuan ? RupiahConvert(String(parseInt(e.harga_satuan))).detail : '-'}</Td>
                        </Tr>
                      ))
                    : <Tr>
                        <Td colSpan={11}>
                          <DataStatus 
                            text="Tidak ada data"
                          />
                        </Td>
                      </Tr>
                  }
                </TBody>
              </Table>
              <Pagination
                dataLength={pagination?.dataLength}
                dataNumber={pagination?.page * pagination?.dataLength - pagination?.dataLength + 1}
                dataPage={
                  pagination?.dataCount < pagination?.dataLength
                    ? pagination?.dataCount
                    : pagination?.page * pagination?.dataLength
                }
                dataCount={pagination?.dataCount}
                currentPage={pagination?.page}
                totalPage={pagination?.totalPage}
                onPaginationChange={({ selected }) =>
                  setPagination({
                    ...pagination,
                    page: selected + 1,
                  })
                }
                onDataLengthChange={(e) =>
                  setPagination({
                    ...pagination,
                    page: 1,
                    dataLength: e.target.value,
                  })
                }
              />
            </>
        }
        
        <ModalPosting 
          show={modal.show}
          getData={getData}
          onHide={() => setModal({...modal, show: false})}
          setAlert={setAlert}
        />
      </CRUDLayout>
    )
  }