import React, { useState } from 'react'
import {
  DataStatus,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  ReadButton
} from "components"
import {
  RupiahConvert,
  DateConvert,
  TableNumber
} from "utilities"
import { ModalDetailJurnalBDP } from '../../components'

const PageListBDPSubkon = ({
  dataBDPSubkon,
  paginationConfig,
  setPaginationConfig,
  searchConfig,
  isPageLoading
}) => {

  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {}
  })

  const ItemCollapse = ({ data }) => {
    const [isReadMoreClick, setisReadMoreClick] = useState(false)
    return (
      <>
        <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
          {data.map((val, index) => {
            if (isReadMoreClick) {
              return <li key={index} index={index}>{val.uraian}</li>
            } else {
              return index < 2 && <li key={index} index={index}>{val.uraian}</li>
            }
          })}
        </ul>
        {data.length > 2 &&
          <div
            className="text-primary"
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => setisReadMoreClick((prev) => !prev)}
          >
            {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
          </div>}
      </>
    )
  }

  if (!dataBDPSubkon || dataBDPSubkon.length < 1 || isPageLoading.subkon) {
    if (isPageLoading.subkon) return <DataStatus loading={isPageLoading.subkon} text="Memuat data ..." />
    return <DataStatus text="Tidak ada data" />
  }

  return (
    <div className='px-2 py-3'>
      <Table>
        <thead>
          <tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi BDP</ThFixed>
            <Th>Item Subkon</Th>
            <Th>Vendor</Th>
            <Th>Jumlah</Th>
            <Th>Akun COA</Th>
            <Th>Keterangan Jurnal</Th>
          </tr>
        </thead>
        <tbody>
          {dataBDPSubkon.map((val, index) => {
            const jumlah = parseFloat(val.qty_realisasi_subkon ?? 0) * parseInt(val.harga_satuan_subkon ?? 0)
            return (
              <tr key={index}>
                <Td>{TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}</Td>
                <Td>
                  <ReadButton
                    size="sm"
                    onClick={() => {
                      setModalConfig({
                        show: true,
                        data: val
                      })
                    }}
                  />
                </Td>
                <Td>{`${DateConvert(new Date(val.tgl_bdp_subkon)).defaultDMY} \n ${val.no_bdp_subkon}`}</Td>
                <Td>{val.nama_item ?? '-'}</Td>
                <Td>{val.nama_vendor ?? '-'}</Td>
                <Td className='text-right'>{RupiahConvert(String(parseInt(jumlah ?? 0))).detail}</Td>
                <Td>
                  <span className='d-block'>{`D: ${val.nomor_akun_debet} - ${val.nama_akun_debit}`}</span>
                  <span className='d-block'>{`K: ${val.nomor_akun_kredit} - ${val.nama_akun_kredit}`}</span>
                </Td>
                <Td>{val.keterangan_jurnal}</Td>
              </tr>
            )
          })}
        </tbody>
      </Table>

      {!searchConfig.status &&
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1}
          dataPage={paginationConfig.dataCount < paginationConfig.dataLength ? paginationConfig.dataCount : paginationConfig.page * paginationConfig.dataLength}
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) => setPaginationConfig({
            ...paginationConfig,
            page: selected + 1
          })}
          onDataLengthChange={(e) => setPaginationConfig({
            ...paginationConfig,
            page: 1,
            dataLength: e.target.value
          })}
        />
      }

      {/* Modal BDP Subkon*/}
      <ModalDetailJurnalBDP
        modalConfig={modalConfig}
        setModalConfig={setModalConfig}
        BDPType="Subkon"
      />
    </div>
  )

}

export default PageListBDPSubkon