import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Row, Col, Modal, Button } from 'react-bootstrap'
import { Alert, ActionButton, DatePicker, Input, SelectSearch, TextArea } from 'components'
import { InfoSectionModal } from '../InfoSection'
import { PostingComplimentApi, TransferOverheadProduksiApi } from 'api'
import { RupiahConvert } from "utilities"

const ModalForm = ({ modalConfig, setModalConfig, dataInfo }) => {
  const { id_compliment, id_compliment_detail, harga_satuan, qty_compliment } = modalConfig.data
  const sub_total = Math.round(parseFloat(qty_compliment || 0) * parseFloat(harga_satuan || 0))
  const history = useHistory()
  const TODAY = new Date()
  const [alertConfig, setAlertConfig] = useState({ show: false, variant: 'primary', text: 'test' })
  const [dropdownCOA, setDropdownCOA] = useState([])

  const fetchCOA = () => {
    PostingComplimentApi.getCOA()
      .then((res) => {
        const coa = res?.data?.data ?? []
        const mapcoa = coa.map((item) => Object({ ...item, value: item.id_coa, label: `${item.nomor_akun} - ${item.nama_akun}` }))
        setDropdownCOA(mapcoa ?? [])
      })
      .catch(() => alert("Gagal memuat data COA!"))
  }

  const formInitialValues = {
    tgl_posting_compliment: TODAY,
    no_posting_compliment: "",
    jumlah: sub_total,
    keterangan_jurnal: "",
    nomor_akun_debet: "1010504",
    nomor_akun_kredit: "1010501",
    id_compliment_master: id_compliment,
    id_compliment_detail: id_compliment_detail
  }
  const formValidationSchema = Yup.object().shape({
    tgl_posting_compliment: Yup.string().required("Pilih tanggal compliment"),
    nomor_akun_debet: Yup.string().required("Pilih COA Debet"),
    nomor_akun_kredit: Yup.string().required("Pilih COA Kredit"),
  })
  const formSubmitHandler = (values, { setSubmitting }) => {
    const finalValue = { ...values, harga_satuan_overhead: values.harga_satuan,
      nomor_akun_debet: values.id_coa_debet,
      nomor_akun_kredit: values.id_coa_kredit,
      nama_coa_debet: dropdownCOA.find((item) => item.value === values.id_coa_debet)?.label ?? "",
      nama_coa_kredit: dropdownCOA.find((item) => item.value === values.id_coa_kredit)?.label ?? "", }

    PostingComplimentApi.save(finalValue)
      .then(() => {
        history.push("/transaksi/posting-compliment", {
          alert: { show: true, variant: 'primary', text: 'Data berhasil disimpan' }
        })
      })
      .catch(() => setAlertConfig({ show: true, variant: 'danger', text: 'Gagal menyimpan data!' }))
      .finally(() => setSubmitting(false))
  }

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: formSubmitHandler,
  })

  const { values, errors, touched, handleChange, setValues, setFieldValue, handleSubmit, isSubmitting, } = formik

  const getNomorHandler = (date = TODAY) => {
    PostingComplimentApi.getNomor({ tanggal: date }).then((res) => {
      const nomorBaru = res.data.data
      setValues({ ...values, tgl_posting_compliment: date, no_posting_compliment: nomorBaru })
    })
  }

  useEffect(() => {
    getNomorHandler()
    fetchCOA()
    return () => { }
  }, [])

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({ show: false, data: {} })}
    >
      <Modal.Header closeButton>
        <b>Posting Compliment</b>
      </Modal.Header>
      <Modal.Body>
        <Alert
          showCloseButton
          onClose={() => setAlertConfig({ ...alertConfig, show: false })}
          {...alertConfig}
        />
        <InfoSectionModal data={modalConfig.data} tipe={modalConfig.tipe} dataInfo={dataInfo} />
        <Row className="d-flex justify-content-start align-items-center">
          <Col>
            <DatePicker
              label="Tgl. Compliment"
              selected={values.tgl_posting_compliment}
              onChange={(date) => getNomorHandler(date)}
              error={Boolean(errors.tgl_posting_compliment && touched.tgl_posting_compliment)}
              errorText={Boolean(errors.tgl_posting_compliment && touched.tgl_posting_compliment) && errors.tgl_posting_compliment}
            />
          </Col>
          <Col>
            <Input
              readOnly
              label="No. Compliment"
              value={values.no_posting_compliment}
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-start align-items-center">
          <Col>
            <Input
              readOnly
              label="Jumlah"
              placeholder="Masukan jumlah"
              value={values?.jumlah ? RupiahConvert(parseInt(values.jumlah).toString()).getWithComa : 'Rp.0'}
              onChange={e => {setFieldValue('jumlah', RupiahConvert(String(e?.target?.value)).default || '')}}
              error={Boolean(errors.jumlah && touched.jumlah)}
              errorText={Boolean(errors.jumlah && touched.jumlah) && errors.jumlah}
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-start align-items-center">
          <Col>
            <SelectSearch
              label="COA Debet"
              name="id_coa_debet"
              placeholder="Pilih COA Debet"
              onChange={val => setFieldValue('id_coa_debet', val.value)}
              option={dropdownCOA}
              defaultValue={dropdownCOA.map(item => item.id_coa === values.id_coa_debet ? item : "")}
              error={errors.id_coa_debet && touched.id_coa_debet && true}
              errorText={errors.id_coa_debet && touched.id_coa_debet && errors.id_coa_debet}
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-start align-items-center">
          <Col>
            <SelectSearch
              label="COA Kredit"
              name="id_coa_kredit"
              placeholder="Pilih COA Kredit"
              onChange={(val) => setFieldValue("id_coa_kredit", val.value)}
              option={dropdownCOA}
              defaultValue={dropdownCOA.map(item => item.id_coa === values.id_coa_kredit ? item : "")}
              error={errors.id_coa_kredit && touched.id_coa_kredit && true}
              errorText={errors.id_coa_kredit && touched.id_coa_kredit && errors.id_coa_kredit}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextArea
              label="Keterangan Transfer"
              value={values.keterangan_jurnal}
              name="keterangan_jurnal"
              rows={5}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={() => setModalConfig({ show: false, data: {} })}
        >
          BATAL
        </Button>

        <ActionButton
          text="Simpan"
          loading={isSubmitting}
          onClick={handleSubmit} />
      </Modal.Footer>
    </Modal >
  )
}

export default ModalForm