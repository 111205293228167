import { useState } from "react";
import { IoAddOutline } from "react-icons/io5";
import {
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  DataStatus,
} from "components";
import { DateConvert, RupiahConvert, TableNumber } from "utilities";

const TabItem = ({ data = [], grandTotal }) => {
  const getTotal = () => data?.reduce((total, { sub_total }) => total + parseInt(sub_total ?? 0), 0);
  return (
    <div className="p-1 bg-white">
      {/* Table Item */}
      <Table>
        <thead className="text-center bg-light">
          <tr>
            <ThFixed>No.</ThFixed>
            <Th style={{ minWidth: 300 }} className="p-1">Item Komponen</Th>
            <Th style={{ width: 100 }} className="p-1">Qty. Produksi</Th>
            <Th style={{ width: 100 }} className="p-1">Satuan</Th>
            <Th style={{ width: 100 }} className="p-1">Harga Satuan</Th>
            <Th style={{ width: 100 }} className="p-1">Sub Total</Th>
          </tr>
        </thead>
        <tbody>
          {data && data?.length > 0 ? (
            data.map((e, i) => (
              <tr key={i}>
                <TdFixed>{i + 1}</TdFixed>
                <Td>{e?.nama_item ?? "-"}</Td>
                <Td textRight>{e?.qty_produksi ? parseFloat(e.qty_produksi) : "-"}{" "}</Td>
                <Td>{e?.kode_satuan ?? "-"}</Td>
                <Td textRight>{RupiahConvert(String(parseFloat(e?.harga_satuan))).getWithComa}</Td>
                <Td textRight>{RupiahConvert(String(parseFloat(e?.harga_satuan ?? 0) * parseFloat(e.qty_produksi ?? 0))).getWithComa}
                </Td>
              </tr>
            ))
          ) : (
            <tr>
              <Td colSpan={11}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </tr>
          )}
          {data && data?.length > 0 
            ? <tr className="bg-light">
                <td className="p-2 align-middle text-right" style={{ fontSize : 14 }} colSpan={5}><b>Total</b></td>
                {/* <Td>{data?.subtotal ? RupiahConvert(String(parseFloat(data?.subtotal) ?? 0)).getWithComa : "Rp.0"}</Td> */}
                <Td textRight>{RupiahConvert(String(parseFloat(grandTotal ?? 0))).getWithComa}</Td>
              </tr>
            : ""
          }
        </tbody>
      </Table>
    </div>
  );
};

export default TabItem;
