import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Row, Col, ButtonGroup } from "react-bootstrap"
import {
  CRUDLayout,
  Alert,
  DataStatus,
  CreateButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  Tr,
  TBody,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ReadButton,
  FilterButton
} from "components"
import { TableNumber, DateConvert, RupiahConvert } from "utilities"
import { PostingComplimentApi } from "api"
import { ModalDetail, ModalFilterComplimentList } from "./Section"
import Axios from "axios"

const PostingComplimentList = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [dataCompliment, setDataCompliment] = useState([])

  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  const [modalConfig, setModalConfig] = useState({ 
    show: false, 
    type: 'detail', 
    data: {} 
  })
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.compliment_list?.filter?.active,
      tgl_posting_compliment_mulai: location?.state?.compliment_list?.filter?.tgl_posting_compliment_mulai,
      tgl_posting_compliment_selesai: location?.state?.compliment_list?.filter?.tgl_posting_compliment_selesai,
      tgl_compliment_mulai: location?.state?.compliment_list?.filter?.tgl_compliment_mulai,
      tgl_compliment_selesai: location?.state?.compliment_list?.filter?.tgl_compliment_selesai,
      customer: location?.state?.compliment_list?.filter?.customer,
      petugas: location?.state?.compliment_list?.filter?.petugas,
      id_barang: location?.state?.compliment_list?.filter?.id_barang,
    },
    pagination: {
      page: location?.state?.compliment_list?.filter?.page ?? "1",
      dataLength: location?.state?.compliment_list?.filter?.dataLength ?? "10",
      totalPage: location?.state?.compliment_list?.filter?.totalPage ?? "1",
      dataCount: location?.state?.compliment_list?.filter?.dataCount ?? "0",
    }
  })
  const [dropdown, setDropdown] = useState({
    customer: [{ value: undefined, label: 'Semua' }],
    petugas: [{ value: undefined, label: 'Semua' }],
    nama_item: [{ value: undefined, label: 'Semua' }],
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    PostingComplimentApi.getPage({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_posting_compliment_mulai: dataFilter?.filter?.tgl_posting_compliment_mulai,
      tgl_posting_compliment_selesai: dataFilter?.filter?.tgl_posting_compliment_selesai,
      tgl_compliment_mulai: dataFilter?.filter?.tgl_compliment_mulai,
      tgl_compliment_selesai: dataFilter?.filter?.tgl_compliment_selesai,
      customer: dataFilter?.filter?.customer,
      petugas: dataFilter?.filter?.petugas,
      id_barang: dataFilter?.filter?.id_barang,
    })
    .then(data => {
      setDataCompliment(data?.data?.data ?? [])
      setDataFilter({
        ...dataFilter,
        pagination: {
          ...dataFilter.pagination,
          dataCount: data?.data?.data_count,
          totalPage: data?.data?.total_page,
        }
      })
    })
    .catch((err) => {
      setDataCompliment([{}])
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "Data gagal dimuat!",
      })
    })
    .finally(() => setIsPageLoading(false))
  }
  const fetchDropdown = () => {
    Axios.all([
      PostingComplimentApi.dropdownCustomer(),
      PostingComplimentApi.dropdownPetugas(),
      PostingComplimentApi.dropdownBarang()
    ])
      .then(Axios.spread((customer, petugas, barang) => {
        const mapCustomer = customer?.data?.data ? customer.data.data.map(val => ({ value: val.id_customer, label: val.nama_customer })) : []
        const mapPetugas = petugas?.data?.data ? petugas.data.data.map(val => ({ value: val.id_karyawan, label: val.nama_karyawan })) : []
        const mapBarang = barang?.data?.data ? barang.data.data.map(val => ({ value: val.id_item_buaso, label: val.nama_item })) : []

        setDropdown((prev) => ({ 
          customer: prev.customer.concat(mapCustomer),
          petugas: prev.petugas.concat(mapPetugas),
          nama_item: prev.nama_item.concat(mapBarang)
        }))
      }))
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }
  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    })
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
  }

  useEffect(() => { fetchDropdown() }, [])
  useEffect(() => {
    setNavbarTitle("Posting Compliment")
    getInitialData()
    checkAlert()
    return () => { setIsPageLoading(false) }
  }, [setNavbarTitle])
  useEffect(() => {
    getInitialData()
    return () => { setIsPageLoading(false) }
  }, [
    searchConfig.key, 
    dataFilter?.pagination?.page, 
    dataFilter?.pagination?.dataLength, 
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_posting_compliment_mulai,
    dataFilter?.filter?.tgl_posting_compliment_selesai,
    dataFilter?.filter?.tgl_compliment_mulai,
    dataFilter?.filter?.tgl_compliment_selesai,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.petugas,
    dataFilter?.filter?.id_barang,
  ])

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr className="text-center">
            <ThFixed>No.</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi Posting Compliment</ThFixed>
            <ThFixed>Informasi Compliment</ThFixed>
            <Th style={{ minWidth: "300px" }}>Customer</Th>
            <Th style={{ minWidth: "200px" }}>Petugas Pemberi Compliment</Th>
            <Th style={{ minWidth: "300px" }}>Item Barang</Th>
            <Th style={{ minWidth: "150px" }}>Jumlah</Th>
            <Th style={{ minWidth: "300px" }}>Akun COA</Th>
            <Th style={{ minWidth: "300px" }}>Keterangan Transfer</Th>
          </Tr>
        </THead>
        <TBody>
          {dataCompliment?.map((val, index) => {
            return (
              <Tr key={index}>
                <Td className="text-center">{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</Td>
                <TdFixed>
                  <ButtonGroup>
                    <ReadButton
                      size="sm"
                      tooltip
                      tooltipText="Detail Transfer Overhead Produksi"
                      className="btn btn-block"
                      onClick={() => setModalConfig({ show: true, type: "detail", data: val })}
                    />
                  </ButtonGroup>
                </TdFixed>
                <Td>
                  <div> {val.tgl_posting_compliment ? DateConvert(new Date(val.tgl_posting_compliment)).defaultDMY : "-"} </div>
                  <div> {val.no_posting_compliment ?? "-"} </div>
                </Td>
                <Td>
                  <div> {val.tgl_compliment ? DateConvert(new Date(val.tgl_compliment)).defaultDMY : "-"} </div>
                  <div> {val.no_compliment ?? "-"} </div>
                </Td>
                <Td>{val.nama_customer ?? "-"}</Td>
                <Td>{val?.nama_petugas ?? "-"}</Td>
                <Td>{val?.nama_item ?? "-"} </Td>
                <Td><div className="text-right">{val?.jumlah ? RupiahConvert(parseInt(val.jumlah || 0).toString()).getWithComa : "Rp.0"}</div></Td>
                <Td>
                  <span className='d-block'>{`D: ${val?.nomor_akun_debet ?? ""} - ${val?.nama_akun_debet ?? "-"}`}</span>
                  <span className='d-block'>{`K: ${val?.nomor_akun_kredit ?? "-"} - ${val?.nama_akun_kredit ?? "-"}`}</span>
                </Td>
                <Td>{val.keterangan_jurnal ?? "-"}</Td>
              </Tr>
            )
          })}
        </TBody>
      </Table >
    )
    if (!dataCompliment || dataCompliment?.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <small className="font-weight-bold">List Data Posting Compliment</small>
        <DataTable />

        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
            }
            dataPage={
              dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                }
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                }
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            </Col>
            <FilterButton 
              active={dataFilter?.filter?.active}
              onClick={() => setModalConfig({ show: true, type: 'filter' })}
            />
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() => history.push("/transaksi/posting-compliment/list-compliment-siap-di-posting", {...location?.state, compliment_list: dataFilter})} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading
        ? (<DataStatus loading={true} text="Memuat data . . ." />)
        : (<PageContent />)
      }

      {/* Modal Detail Posting Compliment */}
      {modalConfig.show && modalConfig.type === "detail" && (<ModalDetail modalConfig={modalConfig} setModalConfig={setModalConfig} />)}

      {/* Modal Filter  */}
      {modalConfig.show && modalConfig.type === "filter" && (
        <ModalFilterComplimentList
          dropdown={dropdown}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  )
}

export default PostingComplimentList