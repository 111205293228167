import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  InfoItemHorizontal,
  InfoItemVertical,
  Input,
  SelectSearch,
  TextArea,
  DatePicker,
  ActionButton,
} from "components";
import { DateConvert, RupiahConvert } from "utilities";
import { Alert } from "react-bootstrap";
import { HargaPokokProduksiApi } from "api";

const ModalPostingHPP = ({ modalConfig, setModalConfig, grandTotalBDP }) => {
  const today = DateConvert(new Date()).default
  const history = useHistory();
  const [confirmBody, setConfirmBody] = useState({
    show: false,
    isConfirm: false,
    dataDebet: {},
    dataKredit: {}
  });
  const [dropdownCOA, setDropdownCOA] = useState([])

  const initialValues = {
    tgl_hpp: '',
    no_hpp: '',
    id_job_order: modalConfig?.data?.id_job_order,
    jumlah: grandTotalBDP?.grandTotal,
    id_realisasi_produksi: modalConfig?.data?.id_realisasi_produksi,
    no_realisasi_produksi: modalConfig?.data?.no_realisasi_produksi,
    no_coa_debet: '5060101',
    no_coa_kredit: '1010504',
    label_coa_debet: '',
    label_coa_kredit: '',
    keterangan_jurnal: '',
  };
  const validationSchema = Yup.object().shape({
    tgl_hpp: Yup.string().required("Tgl. HPP tidak boleh kosong"),
    no_hpp: Yup.string().required("No. HPP tidak boleh kosong"),
    no_coa_debet: Yup.string().required("COA debet tidak boleh kosong"),
    no_coa_kredit: Yup.string().required("COA kredit tidak boleh kosong"),
    keterangan_jurnal: Yup.string().required("Keterangan jurnal tidak boleh kosong"),
  });
  const onSubmit = async (values, { setSubmitting }) => {
    HargaPokokProduksiApi.create(values)
      .then((res) => {
        history.push("/transaksi/hpp/list-produksi", {
          alert: {
            show: true,
            text: "Data berhasil diposting!",
            variant: "primary",
          },
        });
      })
      .catch((response) => {window.alert(response?.response?.data?.message)});
  };

  const fetchCOA = () => {
    HargaPokokProduksiApi.dropdownCOA()
      .then((res) => {
        const coa = res?.data?.data ?? []
        const mapcoa = coa.map((item) => Object({ ...item, value: item.id_coa, label: `${item.nomor_akun} - ${item.nama_akun}` }))
        setDropdownCOA(mapcoa ?? [])
      })
      .catch(() => alert('Gagal memuat data COA!'))
  };

  const InfoSection = ({ data }) => {
    return (
      <>
        <InfoItemHorizontal label="Tgl. Produksi" text={data.tgl_jobmix ? DateConvert(new Date(data.tgl_jobmix)).detail : "-"} />
        <InfoItemHorizontal label="No. Produksi" text={data.no_jobmix ?? "-"} />
        <InfoItemHorizontal label="Item Produksi" text={data.nama_item} />
        <InfoItemHorizontal label="Qty. Produksi" text={data.qty_produksi ? `${parseFloat(data.qty_produksi)} ${data.kode_satuan ?? ""}`: "-"} />
        <InfoItemHorizontal label="Total Qty. Telah Direalisasi" text={data.qty_telah_direalisasi ? `${parseFloat(data.qty_telah_direalisasi)} ${data.kode_satuan ?? ""}` : "-"} />
        <InfoItemHorizontal label="Tgl. Sales Order" text={data.tgl_sales_order ? DateConvert(new Date(data.tgl_sales_order)).detail : "-"} />
        <InfoItemHorizontal label="No. Sales Order" text={data.no_sales_order} />
        <InfoItemHorizontal label="Customer" text={data.nama_customer ?? "-"} />
        <hr />
      </>
    );
  };
  const BodyConfirmation = ({ formik }) => {
    const { values } = formik;
    return (
      <>
        <Row>
          <Col>
            <InfoItemVertical label="Tgl. HPP" text={values?.tgl_hpp ? DateConvert(new Date(values.tgl_hpp)).detail : "-"} />
          </Col>
          <Col>
            <InfoItemVertical label="No. HPP" text={values.no_hpp} />
          </Col>
        </Row>
        <InfoItemVertical label="Jumlah" text={RupiahConvert(String(parseFloat(values.jumlah))).getWithComa} />
        <InfoItemVertical label="Coa Debet" text={confirmBody.dataDebet.label ?? values.label_coa_debet} />
        <InfoItemVertical label="Coa Kredit" text={confirmBody.dataKredit.label ?? values.label_coa_kredit} />
        <InfoItemVertical label="Keterangan Jurnal" text={values.keterangan_jurnal} />
        <Alert variant="danger">
          <small className="font-weight-bold">
            Data HPP yang telah diposting ke SIA, tidak dapat diedit lagi!
          </small>
        </Alert>
      </>
    );
  };
  const BodyForm = ({ formik }) => {
    const { values, setValues, setFieldValue, handleChange, errors, touched, handleSubmit, isSubmitting, setTouched } = formik;
  
    const generateNo = (tanggal = today) => {
      HargaPokokProduksiApi.noBaru({ tanggal })
        .then((res) => setValues({ ...values, tgl_hpp: tanggal, no_hpp: res.data.data }))
        // .catch(({ response }) => window.alert(response.data.message ?? "Tidak dapat memuat nomor baru!"));
    };
    useEffect(() => generateNo(), []);
  
    return (
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <InfoSection data={modalConfig.data} />
          {confirmBody.show 
            ? ( <BodyConfirmation formik={formik} /> ) 
            : 
              <>
                <Row>
                  <Col md="6">
                    <DatePicker
                      label="Tgl. HPP"
                      placeholderText="Pilih tgl. HPP"
                      selected={values?.tgl_hpp ? new Date(values?.tgl_hpp) : undefined}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        const parseDate = DateConvert(new Date(date)).default;
                        generateNo(parseDate);
                      }}
                      error={!!(errors?.tgl_hpp && touched?.tgl_hpp)}
                      errorText={!!(errors?.tgl_hpp && touched?.tgl_hpp) && errors?.tgl_hpp}
                    />
                  </Col>
                  <Col md="6">
                    <Input
                      label="No. HPP"
                      type="text"
                      name="no_hpp"
                      value={values.no_hpp}
                      readOnly={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      label="Jumlah"
                      type="text"
                      name="jumlah"
                      className="text-right"
                      value={RupiahConvert(String(parseInt(values.jumlah ?? 0))).getWithComa}
                      readOnly={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SelectSearch
                      label="COA Debet*"
                      name="no_coa_debet"
                      placeholder="Pilih COA debet"
                      option={dropdownCOA}
                      defaultValue={dropdownCOA.map(item => item.id_coa === values.no_coa_debet ? item : "")}
                      onChange={(val) => setValues({...values, no_coa_debet: val.value, label_coa_debet: val.label})}
                      error={!!(errors.no_coa_debet && touched.no_coa_debet)}
                      errorText={!!(errors.no_coa_debet && touched.no_coa_debet) && errors.no_coa_debet}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SelectSearch
                      label="COA Kredit*"
                      name="no_coa_kredit"
                      placeholder="Pilih COA Kredit"
                      defaultValue={dropdownCOA.map(item => item.id_coa === values.no_coa_kredit ? item : "")}
                      onChange={(val) => setValues({...values, no_coa_kredit: val.value, label_coa_kredit: val.label})}
                      option={dropdownCOA}
                      error={!!(errors.no_coa_kredit && touched.no_coa_kredit)}
                      errorText={!!(errors.no_coa_kredit && touched.no_coa_kredit) && errors.no_coa_kredit}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextArea
                      label="Keterangan Jurnal*"
                      name="keterangan_jurnal"
                      placeholder="Masukan keterangan jurnal"
                      value={values.keterangan_jurnal}
                      onChange={handleChange}
                      error={!!(errors.keterangan_jurnal && touched.keterangan_jurnal)}
                      errorText={!!(errors.keterangan_jurnal && touched.keterangan_jurnal) && errors.keterangan_jurnal}
                    />
                  </Col>
                </Row>
              </>
            }
        </Modal.Body>
        <Modal.Footer>
        <ActionButton
          variant="outline-secondary"
          text="Batal"
          onClick={confirmBody.show
            ? () => setConfirmBody({ show: false })
            : () => setModalConfig({ show: false, data: null })
          }
        />
        <ActionButton
          text="Posting HPP"
          type="button"
          onClick={confirmBody.show
            ? () => handleSubmit()
            : () => {
                // show error field if exist
                setTouched({
                  no_coa_debet: true,
                  no_coa_kredit: true,
                  keterangan_jurnal: true,
                })
                //if no errors field, show confirmation body
                if (Object.keys(errors).length === 0) {
                  setConfirmBody({ 
                    show: true, 
                    dataDebet: dropdownCOA.find(val => val.id_coa === values.no_coa_debet ? val : ""),
                    dataKredit: dropdownCOA.find(val => val.id_coa === values.no_coa_kredit ? val : ""),
                  });
                }
              }
          }
          loading={confirmBody.isConfirm && isSubmitting}
        />
      </Modal.Footer>
      </form>
    );
  };

  useEffect(() => fetchCOA(), [])

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({ show: false, data: null })}>
      <Modal.Header closeButton>
        <b>{confirmBody.show ? "Konfirmasi" : "Posting HPP"}</b>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}>

        {(formik) => <BodyForm formik={formik} />}
      </Formik>
    </Modal>
  );
};

export default ModalPostingHPP;
