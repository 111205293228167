/**
 * Pre form page sebelum membuat data BDP Produksi
 */

 import React, {
    useEffect,
    useState
  } from 'react'
  import {
    useHistory
  } from 'react-router-dom'
  import {
    Modal,
    Row,
    Col
  } from 'react-bootstrap'
  import {
    IoAddOutline,
    IoEyeOutline
  } from 'react-icons/io5'
  import {
    CRUDLayout,
    InputSearch,
    ActionButton,
    BackButton,
    Table,
    Td,
    Th,
    ThFixed,
    Pagination,
    DataStatus
  } from 'components'
  import {
    DateConvert,
    TableNumber
  } from 'utilities'
  import { BDPProduksiApi } from 'api'
  import { DetailRealisasi } from './components/DetailRealisasi'
  import { ButtonGroup } from 'react-bootstrap'
  
  const ListRealisasiProduksiBDP = ({ setNavbarTitle }) => {
    const history = useHistory()
    const [dataRealisasi, setDataRealisasi] = useState([{}])
    const [isPageLoading, setIsPageLoading] = useState(true)
    const [searchConfig, setSearchConfig] = useState({ status: false, key: '' })
    const [modalConfig, setModalConfig] = useState({ show: false, id_realisasi_produksi: null })
    const [paginationConfig, setPaginationConfig] = useState({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0'
    })
  
    const getInitialData = () => {
      BDPProduksiApi.getRealisasiPage({ q: searchConfig.key, page: paginationConfig.page, per_page: paginationConfig.dataLength })
        .then((res) => {
          const data = res?.data?.data
  
          setDataRealisasi(data)
          setPaginationConfig((prev) => ({
            ...prev,
            dataCount: res?.data.data_count,
            totalPage: res?.data.total_page,
          }))
        })
        .finally(() => setIsPageLoading(false))
    }
  
    const onInputSearchChange = e => {
      const key = e.target.value
      setSearchConfig(() => ({ key, status: (key.length > 0) ? true : false }))
      setPaginationConfig({
        page: '1',
        dataLength: '10',
        totalPage: '1',
        dataCount: '0'
      })
    }
  
    useEffect(() => {
      setNavbarTitle('BDP Produksi')
      getInitialData()
  
      return () => { }
    }, [searchConfig.key, paginationConfig.page, paginationConfig.dataLength])
  
    return (
      <CRUDLayout>
        <CRUDLayout.Head>
          <CRUDLayout.HeadSearchSection>
            <Row className="mb-2">
              <Col md={8}>
                <InputSearch
                  value={searchConfig.key}
                  onChange={onInputSearchChange}
                />
              </Col>
            </Row>
          </CRUDLayout.HeadSearchSection>
          <CRUDLayout.HeadButtonSection>
            <BackButton onClick={() => history.goBack()} />
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>
  
        {isPageLoading
          ? <DataStatus loading={isPageLoading} text="Memuat data ..." />
          : dataRealisasi?.length > 0 ? (
            <>
              <small className='font-weight-bold'>List Data Realisasi Produksi</small>
              <Table className="mt-2">
                <thead>
                  <tr>
                    <ThFixed>No.</ThFixed>
                    <ThFixed>Informasi Realisasi Produksi</ThFixed>
                    <ThFixed>Informasi Job Order</ThFixed>
                    <Th>Customer</Th>
                    <Th>Item Produksi</Th>
                    <Th width={100}>Qty. Job Order</Th>
                    <Th width={100}>Qty. Realisasi Produksi</Th>
                    <ThFixed>Aksi</ThFixed>
                  </tr>
                </thead>
                <tbody>
                  {dataRealisasi?.map((val, index) => (
                    <tr key={index}>
                      <Td>{TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}</Td>
                      <Td>
                        <div> {DateConvert(new Date(val.tgl_realisasi_produksi)).defaultDMY} </div>
                        <div> {val.no_realisasi_produksi ?? '-'} </div>
                      </Td>
                      <Td>
                        <div> {DateConvert(new Date(val.tgl_job_order)).defaultDMY} </div>
                        <div> {val.no_job_order ?? '-'} </div>
                      </Td>
                      <Td>{val.nama_customer ?? '-'}</Td>
                      <Td>{val.nama_item}</Td>
                      <Td className="text-right">{val.qty_produksi ? `${parseFloat(val.qty_produksi)} ${val.nama_satuan ?? ""}` : '-'}</Td>
                      <Td className="text-right">{val.qty_realisasi_produksi ? `${parseFloat(val.qty_realisasi_produksi)} ${val.nama_satuan ?? ""}` : '-'}</Td>
                      <Td>
                        <ButtonGroup>
                          <ActionButton
                            variant="primary"
                            size="sm"
                            onClick={() => setModalConfig({ show: true, id_realisasi_produksi: val.id_realisasi_produksi })}
                          >
                            <IoEyeOutline />
                          </ActionButton>
                          <ActionButton
                            variant="primary"
                            size="sm"
                            onClick={() => history.push(`/transaksi/bdp-produksi/data/${val.id_realisasi_produksi}`, val)}
                          >
                            <IoAddOutline />
                          </ActionButton>
  
                        </ButtonGroup>
  
                      </Td>
                    </tr>
                  ))}
                </tbody>
              </Table>
  
              {!searchConfig.status &&
                <Pagination
                  dataLength={paginationConfig.dataLength}
                  dataNumber={paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1}
                  dataPage={paginationConfig.dataCount < paginationConfig.dataLength ? paginationConfig.dataCount : paginationConfig.page * paginationConfig.dataLength}
                  dataCount={paginationConfig.dataCount}
                  currentPage={paginationConfig.page}
                  totalPage={paginationConfig.totalPage}
                  onPaginationChange={({ selected }) => setPaginationConfig({
                    ...paginationConfig,
                    page: selected + 1
                  })}
                  onDataLengthChange={(e) => setPaginationConfig({
                    ...paginationConfig,
                    page: 1,
                    dataLength: e.target.value
                  })}
                />
              }
            </>
          ) : <DataStatus text="Tidak ada data" />
        }
  
        <Modal
          size="xl"
          show={modalConfig.show}
          onHide={() => setModalConfig({ id_realisasi_produksi: null, show: false })}
        >
          <Modal.Header closeButton>
            <b>Detail Data Realisasi Produksi</b>
          </Modal.Header>
          <Modal.Body>
            <DetailRealisasi modalConfig={modalConfig} />
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-end'>
            <ActionButton
              variant="outline-dark"
              size="md"
              onClick={() => setModalConfig({ id_realisasi_produksi: null, show: false })}
              text="Tutup"
            />
          </Modal.Footer>
        </Modal>
      </CRUDLayout>
    )
  }
  
  export default ListRealisasiProduksiBDP