import Services from 'services'

class BDPProduksi {
  // Get BDP
  getBDPBahan(params) {
    return Services.get('/bdp_bahan/page', { params })
  }

  getBDPUpah(params) {
    return Services.get('/bdp_upah/page', { params })
  }

  getBDPSubkon(params) {
    return Services.get('/bdp_subkon/page', { params })
  }

  getBDPOverhead(params) {
    return Services.get('/bdp_overhead/page', { params })
  }

  getHistoriBDP(params) {
    return Services.get('/bdp/history', { params })
  }

  // Get Realisasi 
  getRealisasiPage(params) {
    return Services.get('/bdp/realisasi_page', { params })
  }

  getRealisasiSingle(params) {
    return Services.get('/bdp/realisasi_single', { params })
  }

  getRealisasiHistory(params) {
    return Services.get('/bdp/realisasi_history', { params })
  }

  getCOA() {
    return Services.get("/bdp_bahan/dropdown")
  }

  // Get Nomor
  getNomorBahan(params) {
    return Services.get("/bdp_bahan/no_baru", { params })
  }

  getNomorUpah(params) {
    return Services.get("/bdp_upah/no_baru", { params })
  }

  getNomorSubkon(params) {
    return Services.get('/bdp_subkon/no_baru', { params })
  }

  getNomorOverhead(params) {
    return Services.get('/bdp_overhead/no_baru', { params })
  }

  // Create BDP
  postBDPBahan(data) {
    return Services.post('/bdp_bahan', data)
  }

  postBDPUpah(data) {
    return Services.post('/bdp_upah', data)
  }

  postBDPSubkon(data) {
    return Services.post('/bdp_subkon', data)
  }

  postBDPOverhead(data) {
    return Services.post('/bdp_overhead', data)
  }
}

export default new BDPProduksi()
