import React, { useState } from "react"
import { Table, Th, ThFixed, Td, DataStatus, ReadButton, Tr, THead } from "components"
import { DateConvert } from "utilities"
import { ModalDetail } from ".."
import {
  RupiahConvert
} from 'utilities'

const TableHistory = ({ data }) => {
  const [modalConfig, setModalConfig] = useState({ show: false, data: {}, type: "" })

  return (
    <React.Fragment>
      <div className="table-responsive">
        <Table>
          <THead>
            <Tr>
              <ThFixed>No.</ThFixed>
              <ThFixed>Informasi Posting Compliment</ThFixed>
              <Th>Item Barang</Th>
              <Th width={130}>Jumlah</Th>
              <Th width={240}>Akun COA</Th>
              <Th width={200}>Keterangan</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <tbody>
            {data?.length > 0 && data?.map((val, index) => (
              <Tr key={index}>
                <Td width={20}><div className="text-center">{index + 1}</div></Td>
                <Td>
                  <div>{val.tgl_posting_compliment ? DateConvert(new Date(val.tgl_posting_compliment)).defaultDMY : "-"} </div>
                  <div>{val.no_posting_compliment ?? "-"}</div>
                </Td>
                <Td>{val?.nama_item ?? "-"} </Td>
                <Td className="text-right">{val?.jumlah ? RupiahConvert(parseInt(val.jumlah || 0).toString()).getWithComa : "Rp.0"}</Td>
                <Td>
                  <span className='d-block'>{`D: ${val?.nomor_akun_debet ?? ""} - ${val?.nama_akun_debet ?? ""}`}</span>
                  <span className='d-block'>{`K: ${val?.nomor_akun_kredit ?? ""} - ${val?.nama_akun_kredit ?? ""}`}</span>
                </Td>
                <Td>{val.keterangan_jurnal ?? "-"} </Td>
                <Td>
                  <ReadButton size="sm" className="btn btn-block" onClick={() => setModalConfig({ show: true, data: val, type: "history" })} />
                </Td>
              </Tr>
            ))}

            {/* Handle tidak ada data */}
            {data?.length === 0 && (
              <tr>
                <Td colSpan={7} textCenter>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Modal Detail Compliment */}
      {modalConfig.show && (<ModalDetail
        modalConfig={modalConfig}
        setModalConfig={setModalConfig}
      />)}
    </React.Fragment>
  )
}

export default TableHistory
