import { useState, useEffect } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { Card } from "react-bootstrap"
import Axios from "axios"
import { BackButton, DataStatus } from "components"
import { PostingComplimentApi } from "api"
import { TableItemCompliment, TableHistory, InfoSectionComplimentPost } from "./Section"

const PostingCompliment = ({ setNavbarTitle }) => {
  const TODAY = new Date()
  const history = useHistory()
  const location = useLocation()
  const { id_compliment } = useParams()
  const { pagination } = location.state
  const [isFetching, setIsFetching] = useState({ loading: false, success: false })
  const [dataInfo, setDataInfo] = useState([])
  const [data, setData] = useState([])
  const [dataHistory, setDataHistory] = useState([])
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  })

  const getInitialData = () => {
    setIsFetching({ loading: true, success: false })

    Axios.all([
      PostingComplimentApi.getSinglePreForm({ id_compliment: id_compliment }),
      PostingComplimentApi.getHistory({ 
        id_compliment_master: id_compliment,
        page: paginationConfig.page,
        per_page: paginationConfig.dataLength,
      })
    ])
      .then(Axios.spread((resSingle, resHistory) => {
        setDataInfo(resSingle?.data?.data ?? [])
        setData(resSingle?.data?.data?.detail ?? [])
        setDataHistory(resHistory?.data?.data ?? [])
        console.log("res", resHistory)
        setIsFetching({ loading: false, success: true })
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data?.data_count,
          totalPage: data?.total_page,
        })
      }))
      .catch(() => {
        setIsFetching({ loading: false, success: false })
        window.alert("Gagal memuat data!")
      })
      .finally(() => setIsFetching({ loading: false, success: false }))
  }
  useEffect(() => {
    setNavbarTitle("Posting Compliment")
    getInitialData()
  }, [setNavbarTitle])

  return (
    <>
      <div className="mb-2 d-flex justify-content-end align-items-center">
        <BackButton
          onClick={() => history.push("/transaksi/posting-compliment/list-compliment-siap-di-posting", { ...location?.state })}
        />
      </div>
      <div className="font-weight-bold mb-1">Detail Data Compliment</div>

      {isFetching.loading
        ? <DataStatus loading={isFetching} text="Memuat Data . . ." />
        : <>
            <Card>
              <Card.Body>
                <InfoSectionComplimentPost data={dataInfo} />
              </Card.Body>
            </Card>

            <div className="font-weight-bold mb-1 mt-3">Item Compliment</div>
            <TableItemCompliment
              type="create"
              data={data}
              setData={setData}
              dataInfo={dataInfo}
              dataHistory={dataHistory}
            />

            <div className="font-weight-bold mb-1 mt-3">History Posting Compliment</div>
            <TableHistory data={dataHistory} />
          </>
      }
    </>
  )
}

export default PostingCompliment
