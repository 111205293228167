import React, { useState, useEffect } from 'react'
import Axios from "axios"
import { useHistory, useParams } from 'react-router-dom'
import {
  Card,
  Nav,
  Tab,
  Row,
  Col
} from 'react-bootstrap'
import {
  Alert,
  BackButton,
  InfoItemHorizontal,
  DataStatus,
  InfoItemVertical
} from 'components'
import {
  DateConvert
} from 'utilities'
import { BDPProduksiApi } from 'api'
import { TableRealisasiBahan, TableHistoryBahan } from "./components/bahan"
import { TableRealisasiUpah, TableHistoryUpah } from "./components/upah"
import { TableRealisasiOverhead, TableHistoryOverhead } from "./components/overhead"
import { TableRealisasiSubkon, TableHistorySubkon } from "./components/subkon"

const DataBDPProduksi = ({ setNavbarTitle }) => {
  const history = useHistory()
  const { id_realisasi_produksi } = useParams()
  const [dataDetail, setDataDetail] = useState({})
  const [bahan, setBahan] = useState([])
  const [upah, setUpah] = useState([])
  const [subkon, setSubkon] = useState([])
  const [overhead, setOverhead] = useState([])
  const [dataHistory, setDataHistory] = useState({ bahan: [], upah: [], subkon: [], overhead: [] })
  const [isLoading, setLoading] = useState(true)
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'danger',
    text: ''
  })
  const style = { fontSize: 14 }

  const getData = () => {
    Axios.all([
      BDPProduksiApi.getRealisasiSingle({ id_realisasi_produksi }),
      BDPProduksiApi.getHistoriBDP({ id_realisasi_produksi })
    ])
      .then((Axios.spread((res, resHistory) => {
        setDataDetail(res?.data?.data)
        setBahan(res?.data?.data?.bahan ?? [])
        setUpah(res?.data?.data?.upah ?? [])
        setSubkon(res?.data?.data?.subkon ?? [])
        setOverhead(res?.data?.data?.overhead ?? [])

        const resHistori = resHistory?.data?.data ?? {}
        setDataHistory({
          bahan: resHistori?.bahan?.map(i => ({ ...i, tgl_bdp: i.tgl_bdp_bahan, no_bdp: i.no_bdp_bahan })) ?? [],
          upah: resHistori?.upah?.map(i => ({ ...i, tgl_bdp: i.tgl_bdp_upah, no_bdp: i.no_bdp_upah })) ?? [],
          subkon: resHistori?.subkon?.map(i => ({ ...i, tgl_bdp: i.tgl_bdp_subkon, no_bdp: i.no_bdp_subkon })) ?? [],
          overhead: resHistori?.overhead?.map(i => ({ ...i, tgl_bdp: i.tgl_bdp_overhead, no_bdp: i.no_bdp_overhead })) ?? [],
        })
      })))
      .catch(() => { })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    setNavbarTitle('BDP Produksi')
    getData()
  }, [])

  return (
    <React.Fragment>
      {/* Informasi Job Order Section */}
      <div className="d-flex justify-content-between align-items-center mb-2">
        <b>Informasi Realisasi Produksi</b>
        <BackButton onClick={() => history.goBack()} />
      </div>
      <Card className="mb-4">
        <Card.Body>
          {isLoading
            ? <DataStatus loading={isLoading} text="Memuat Data . . ." />
            : <>
              <Row>
                <Col>
                  <InfoItemHorizontal label="Tgl. Job Order" text={dataDetail?.tgl_job_order ? DateConvert(new Date(dataDetail?.tgl_job_order)).detail : "-"} />
                  <InfoItemHorizontal label="No. Job Order" text={dataDetail?.no_job_order ?? '-'} />
                  <InfoItemHorizontal label="Kode Item Produksi" text={dataDetail?.kode_item ?? '-'} />
                  <InfoItemHorizontal label="Item Produksi" text={dataDetail?.nama_item ?? '-'} />
                  <InfoItemHorizontal label="Keperluan" text={dataDetail?.keperluan_produksi ?? '-'} />
                </Col>
                <Col>
                  <InfoItemHorizontal label="Qty. Job Order" text={dataDetail?.qty_produksi ? `${parseFloat(dataDetail.qty_produksi)} ${dataDetail?.nama_satuan ?? ""}` : '-'} />
                  <InfoItemHorizontal label="Total Qty. Telah Direalisasi" text={dataDetail?.qty_realisasi_produksi ? `${parseFloat(dataDetail.qty_realisasi_produksi)} ${dataDetail?.nama_satuan ?? ""}` : '-'} />
                  <InfoItemHorizontal label="Petugas Produksi" text={dataDetail?.nama_karyawan ?? '-'} />
                  <InfoItemHorizontal label="Depo Produksi" text={dataDetail?.nama_depo ?? '-'} />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <InfoItemVertical label="Tgl. Realisasi Produksi" text={dataDetail?.tgl_realisasi_produksi ? DateConvert(new Date(dataDetail?.tgl_realisasi_produksi)).detail : "-"} />
                </Col>
                <Col>
                  <InfoItemVertical label="No. Realisasi Produksi" text={dataDetail?.no_realisasi_produksi ?? "-"} />
                </Col>
                <Col>
                  <InfoItemVertical label="Qty. Realisasi Item Produksi" text={dataDetail?.qty_realisasi_produksi ? `${parseFloat(dataDetail?.qty_realisasi_produksi)} ${dataDetail?.nama_satuan ?? ""}` : '-'} />
                </Col>
              </Row>
            </>
          }
        </Card.Body>
      </Card>

      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig((prev) => ({ ...prev, show: false }))}
      />

      {/* Data Realisasi Produksi Section */}
      <span className='font-weight-bold mb-1'>Data Realisasi Produksi</span>
      <Card className='border'>
        <Tab.Container defaultActiveKey='bdp_bahan'>
          <Card.Header className='className="d-flex justify-content-between align-items-center'>
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="bdp_bahan">BDP Bahan</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="bdp_upah">BDP Upah</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="bdp_subkon">BDP Subkon</Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link eventKey="bdp_overhead">BDP Overhead</Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>

          <Tab.Content>
            {isLoading
              ? <DataStatus loading={isLoading} text="Memuat Data . . ." />
              : (
                <>
                  <Tab.Pane eventKey="bdp_bahan">
                    <div className='px-3 py-3'>
                      <small className='font-weight-bold mb-1' style={style}>List Realisasi Item Bahan</small>
                      <TableRealisasiBahan
                        dataList={bahan}
                        dataHistory={dataHistory.bahan ?? []}
                        getData={getData}
                      />

                      <small className='font-weight-bold mb-1' style={style}>History BDP Bahan</small>
                      <TableHistoryBahan dataHistory={dataHistory.bahan ?? []} />
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="bdp_upah">
                    <div className='px-3 py-3'>
                      <small className='font-weight-bold mb-1' style={style}>List Realisasi Item Upah</small>
                      <TableRealisasiUpah
                        dataList={upah}
                        dataHistory={dataHistory.upah ?? []}
                        getData={getData}
                      />

                      <small className='font-weight-bold mb-1' style={style}>History BDP Upah</small>
                      <TableHistoryUpah dataHistory={dataHistory.upah ?? []} />
                    </div>
                  </Tab.Pane>

                  {/* <Tab.Pane eventKey="bdp_subkon">
                    <div className='px-3 py-3'>
                      <small className='font-weight-bold mb-1' style={style}>List Realisasi Item Subkon</small>
                      <TableRealisasiSubkon
                        dataList={subkon}
                        dataHistory={dataHistory.subkon ?? []}
                        getData={getData}
                      />

                      <small className='font-weight-bold mb-1' style={style}>History BDP Subkon</small>
                      <TableHistorySubkon dataHistory={dataHistory.subkon ?? []} />
                    </div>
                  </Tab.Pane> */}

                  <Tab.Pane eventKey="bdp_overhead">
                    <div className='px-3 py-3'>
                      <small className='font-weight-bold mb-1' style={style}>List Realisasi Item Overhead</small>
                      <TableRealisasiOverhead
                        dataList={overhead}
                        dataHistory={dataHistory.overhead ?? []}
                        getData={getData}
                      />

                      <small className='font-weight-bold mb-1' style={style}>History BDP Overhead</small>
                      <TableHistoryOverhead dataHistory={dataHistory.overhead ?? []} />
                    </div>
                  </Tab.Pane>
                </>
              )
            }
          </Tab.Content>
        </Tab.Container>
      </Card>
    </React.Fragment>
  )
}

export default DataBDPProduksi