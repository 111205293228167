import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { InfoItemHorizontal } from 'components'
import { DateConvert } from 'utilities'

const InfoSectionComplimentPost = ({ data }) => {
  return (
    <React.Fragment>
      <Row>
        <Col md={6}>
          <InfoItemHorizontal
            label="Tgl. Compliment"
            width={200}
            text={data?.tgl_compliment ? DateConvert(new Date(data?.tgl_compliment)).detail : "-"}
          />
          <InfoItemHorizontal
            label="No. No Compliment"
            width={200}
            text={data?.no_compliment ?? "-"}
          />
          <InfoItemHorizontal
            label="Customer"
            width={200}
            text={data?.customer ?? "-"}
          />
          <InfoItemHorizontal
            label="Petugas Pemberi compliment"
            width={200}
            text={data?.petugas ?? "-"}
          />
        </Col>
        <Col md={6}>
          <InfoItemHorizontal
            label="Keterangan"
            width={200}
            text={data?.keterangan ?? "-"}
          />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default InfoSectionComplimentPost