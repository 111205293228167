import Services from "services";

class HargaPokokProduksiApi {
  page(params) {
    return Services.get("/hpp/page", { params });
  }

  JOPage(params) {
    return Services.get("/hpp/list_jo", { params });
  }

  // get single HPP
  getSingle(params) {
    return Services.get("/hpp/single", { params }); 
  }

  // get single produksi (preform) untuk post HPP baru
  getSingleProduksi(params) {
    return Services.get("/hpp/single_realisasi", { params });
  }

  //  get single produksi (preform) untuk lihat detail produksi
  getSingleProduksiList(params) {
    return Services.get("/hpp/single_jo", { params });
  }

  dropdownCOA() {
    return Services.get("/hpp/dropdown_coa");
  }

  dropdown() {
    return Services.get("/hpp/dropdown_bahan");
  }

  noBaru(params) {
    return Services.get("/hpp/no_baru", { params });
  }

  create(value) {
    return Services.post("/hpp", value);
  }

  export(params) {
    return Services.get("/hpp/export", { params });
  }
}

export default new HargaPokokProduksiApi();
