import {
  IoCashOutline,
  IoSpeedometerOutline,
  IoServerOutline,
  IoDocumentTextOutline,
} from "react-icons/io5"

/* 
  MENU REQUIREMENTS
    > TEXT  = REQUIRED
    > LINK  = REQUIRED
    > EXACT = OPTIONAL (TRUE/FALSE)
    > TYPE  = REQUIRED (MENU/DROPDOWN)
    > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
    > ICON  = OPTIONAL (REACT-ICONS)
*/

export default [
  {
    //Dashboard
    text: "Dashboard",
    link: "/",
    type: "menu",
    exact: true,
    icon: <IoSpeedometerOutline />,
    hak: ["SUPA", "COC"],
  },
  {
    // Master
    text: "Master",
    type: "dropdown",
    icon: <IoServerOutline />,
    hak: ["SUPA", "COC"],
    menu: [
      {
        text: "Item Overhead",
        link: "/master/item-overhead",
        hak: ["SUPA", "COC_REG_OVH"],
      },
      {
        text: "Kelompok Overhead",
        link: "/master/kelompok-overhead",
        hak: ["SUPA", "COC_MAS_KLO"],
      },
      {
        text: "Harga Satuan Rata-Rata",
        link: "/master/harga-satuan-rata-rata",
        hak: ["SUPA", "COC_MAS_HPS"],
      },
      // {
      //   text: "Harga Perkiraan Sendiri",
      //   link: "/master/harga-perkiraan-sendiri",
      //   hak: ["SUPA", "COC_MAS_HPS"],
      // },
    ],
  },
  {
    // Transaksi
    text: "Transaksi",
    type: "dropdown",
    icon: <IoCashOutline />,
    hak: ["SUPA", "COC"],
    menu: [
      {
        text: "Transfer Overhead Produksi",
        link: "/transaksi/transfer-overhead-produksi",
        hak: ["SUPA", "COC_TRN_TPO"],
      },
      {
        text: "BDP Produksi",
        link: "/transaksi/bdp-produksi",
        hak: ["SUPA", "COC_TRN_BDP"],
      },
      {
        text: "Harga Satuan Rata-Rata Bahan",
        link: "/transaksi/harga-satuan-rata-rata-bahan",
        hak: ["SUPA", "COC_TRN_HSB"],
      },
      {
        text: "Harga Pokok Produksi",
        link: "/transaksi/hpp",
        hak: ["SUPA", , "COC_TRN_HPP"],
      },
      {
        text: "Posting Compliment",
        link: "/transaksi/posting-compliment",
        hak: ["SUPA", "COC_TRN_PCMP"],
      },
    ],
  },
  {
    // Laporan
    text: "Laporan",
    type: "dropdown",
    icon: <IoDocumentTextOutline />,
    hak: ["SUPA"],
    menu: [
      {
        text: "Comming Soon",
        link: "/soon",
        hak: ["SUPA"],
      },
    ],
  },
]