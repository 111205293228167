import Services from "services"

class PostingComplimentApi {
  getPage(params) {
    return Services.get("/posting_compliment/page", { params })
  }

  getPreFormPage(params) {
    return Services.get("/posting_compliment/page_compliment", { params })
  }
  
  getSingle(params) {
    return Services.get("/posting_compliment/detail", { params })
  }

  getSinglePreForm(params) {
    return Services.get("/posting_compliment/single_compliment", { params })
  }

  getCOA() {
    return Services.get("/posting_compliment/dropdown")
  }
  
  getNomor(params) {
    return Services.get("/posting_compliment/no_baru", { params })
  }

  getHistory(params) {
    return Services.get("/posting_compliment/history", { params })
  }

  dropdownCustomer(params) {
    return Services.get("/dropdown/customer", { params })
  }

  dropdownPetugas(params) {
    return Services.get("/dropdown/petugas", { params })
  }

  dropdownBarang(params) {
    return Services.get("/dropdown/item_barang", { params })
  }

  save(data) {
    return Services.post("/posting_compliment", data)
  }
}

export default new PostingComplimentApi()
