import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { InfoItemHorizontal, InfoItemVertical } from "components";
import { DateConvert, RupiahConvert } from "utilities";

const InfoSection = ({ data, type, data_type }) => {
  console.log("type", data_type)
  console.log("test", data);
  return (
    <Card className="mb-4">
      <Card.Body>
        <Row>
          <Col lg>
            {data_type === "hpp_create" || data_type === "produksi_detail" ?
              <table>
                <tbody>
                  <InfoItemHorizontal
                    label="Tgl. Job Order"
                    text={data?.tgl_jobmix ? DateConvert(new Date(data?.tgl_jobmix)).detail : "-"}
                  />
                  <InfoItemHorizontal
                    label="No. Job Order"
                    text={data?.no_jobmix ?? "-"}
                  />
                  <InfoItemHorizontal
                    label="Tgl. Sales Order"
                    text={data?.tgl_sales_order ? DateConvert(new Date(data?.tgl_sales_order)).detail : "-"}
                  />
                  <InfoItemHorizontal
                    label="No. Sales Order"
                    text={data?.no_sales_order ?? "-"}
                  />
                  <InfoItemHorizontal
                    label="Nama Item"
                    text={data?.nama_item ?? "-"}
                  />
                  <InfoItemHorizontal
                    label="Petugas Produksi"
                    text={data?.nama_karyawan ?? "-"}
                  />
                  <InfoItemHorizontal
                    label="Customer"
                    text={data?.nama_customer ?? "-"}
                  />
                </tbody>
              </table>
              :
              <table>
                <tbody>
                  <InfoItemHorizontal
                    label="Tgl. Produksi"
                    text={data?.tgl_jobmix ? DateConvert(new Date(data?.tgl_jobmix)).detail : "-"}
                  />
                  <InfoItemHorizontal
                    label="No. Produksi"
                    text={data?.no_jobmix ?? "-"}
                  />
                  <InfoItemHorizontal
                    label="Item Produksi"
                    text={data?.nama_item ?? "-"}
                  />
                  <InfoItemHorizontal
                    label="Qty. Produksi"
                    text={data?.qty_produksi ? `${parseFloat(data?.qty_produksi ?? 0)} ${data?.nama_satuan ?? "" }` : "-"}
                  />
                  <InfoItemHorizontal
                    label="Total Qty. Telah Realisasi"
                    text={data.qty_telah_direalisasi ? `${parseFloat(data.qty_telah_direalisasi ?? 0)} ${data.nama_satuan ?? "" }` : "-"}
                  />
                </tbody>
              </table>
            }
          </Col>
          <Col lg>
            {data_type === "hpp_list" ?
              <table>
                <tbody>
                  <InfoItemHorizontal
                    label="Tgl. Sales Order"
                    text={data?.tgl_jobmix ? DateConvert(new Date(data?.tgl_jobmix)).detail : "-"}
                  />
                  <InfoItemHorizontal
                    label="No. Sales Order"
                    text={data?.no_jobmix ?? "-"}
                  />
                  <InfoItemHorizontal label="Customer" text={data?.nama_customer ?? "-"} />
                </tbody>
              </table>
              :
              <table>
                <tbody>
                  <InfoItemHorizontal label="Keperluan Produksi" text={data?.nama_keperluan ?? "-"} />
                  <InfoItemHorizontal
                    label="Qty. Sales Order"
                    text={data.qty_so? `${parseFloat(data.qty_so?? 0)} ${data.kode_satuan}` : "-" }
                  />
                  <InfoItemHorizontal
                    label="Qty. Produksi"
                    text={data.qty_produksi ? `${parseFloat(data.qty_produksi ?? 0)} ${data.kode_satuan}` : "-"}
                  />
                  <InfoItemHorizontal
                    label="Qty. Telah Direalisasi"
                    text={data.qty_telah_direalisasi ? `${parseFloat(data.qty_telah_direalisasi ?? 0)} ${data.kode_satuan}` : "-"}
                  />
                  {/* <InfoItemHorizontal label="Depo" text={data?.nama_depo ?? "-"} /> */}
                  <InfoItemHorizontal label="Gudang" text={data?.nama_gudang ?? "-"} />
                  <InfoItemHorizontal label="Status Progress" text={data?.status_progress ?? "-"} />
                </tbody>
              </table>
            }
          </Col>
        </Row>

        {/* Only show in Detail HPP */}
        {type === "DETAIL" && (
          <>
            <hr />
            <Row>
              <Col>
                <Row>
                  <Col>
                    <InfoItemVertical
                      label="Tgl. HPP"
                      text={data.tgl_hpp ? DateConvert(new Date(data.tgl_hpp)).detail : "-"}
                    />
                  </Col>
                  <Col>
                    <InfoItemVertical
                      label="No. HPP"
                      text={data.no_hpp ?? "-"}
                    />
                  </Col>
                </Row>
                <InfoItemVertical
                  label="COA Debet"
                  text={data.nomor_akun_debet && data.nama_akun_debet ? `${data.nomor_akun_debet} - ${data.nama_akun_debet}` : "-"}
                />
                <InfoItemVertical
                  label="COA Kredit"
                  text={data.nomor_akun_kredit && data.nama_akun_kredit ? `${data.nomor_akun_kredit} - ${data.nama_akun_kredit}` : "-"}
                />
              </Col>

              <Col>
                <InfoItemVertical
                  label="Jumlah"
                  text={data.jumlah ? RupiahConvert(String(parseFloat(data.jumlah))).getWithComa : "-"}
                />
                <InfoItemVertical
                  label="Keterangan Jurnal"
                  text={data.keterangan_jurnal ?? "-"}
                />
              </Col>
            </Row>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default InfoSection;
