import React, { useState } from 'react'
import {
  DataStatus,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  ReadButton
} from "components"
import {
  RupiahConvert,
  DateConvert,
  TableNumber
} from "utilities"
import { ModalDetailJurnalBDP } from '../../components'

const PageListBDPOverhead = ({
  dataBDPOverhead,
  paginationConfig,
  setPaginationConfig,
  searchConfig,
  isPageLoading
}) => {
  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {}
  })

  if (!dataBDPOverhead || dataBDPOverhead.length < 1 || isPageLoading.overhead) {
    if (isPageLoading.overhead) return <DataStatus loading={isPageLoading.overhead} text="Memuat data ..." />
    return <DataStatus text="Tidak ada data" />
  }

  return (
    <div className='px-2 py-3'>
      <Table>
        <thead>
          <tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi BDP</ThFixed>
            <Th>Item Overhead</Th>
            <Th>Qty</Th>
            <Th>Harga Satuan</Th>
            <Th>Jumlah</Th>
            <Th style={{ minWidth: 250 }}>Akun COA</Th>
            <Th style={{ width: 200 }}>Keterangan Jurnal</Th>

          </tr>
        </thead>
        <tbody>
          {dataBDPOverhead.map((val, index) => {
            const jumlah = parseFloat(val.qty_realisasi_overhead ?? 0) * parseInt(val.harga_satuan_overhead ?? 0)

            return (
              <tr key={index}>
                <Td>{TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}</Td>
                <Td>
                  <ReadButton
                    size="sm"
                    onClick={() => setModalConfig({
                      show: true,
                      data: val
                    })}
                  />
                </Td>
                <Td>{`${DateConvert(new Date(val.tgl_bdp_overhead)).defaultDMY} \n ${val.no_bdp_overhead}`}</Td>
                <Td>{val.nama_item ?? '-'}</Td>
                <Td>{`${val.qty_realisasi_overhead ?? '0'} ${val.nama_satuan ?? ''}`}</Td>
                <Td className='text-right'>{RupiahConvert(String(parseInt(val.harga_satuan_overhead ?? 0))).detail}</Td>
                <Td className="text-right">{RupiahConvert(String(parseInt(jumlah ?? 0))).detail}</Td>
                <Td>
                  <span className='d-block'>{`D: ${val.nomor_akun_debet} - ${val.nama_akun_debit}`}</span>
                  <span className='d-block'>{`K: ${val.nomor_akun_kredit} - ${val.nama_akun_kredit}`}</span>
                </Td>
                <Td>{val.keterangan_jurnal}</Td>
              </tr>
            )

          })}
        </tbody>
      </Table>

      {!searchConfig.status &&
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1}
          dataPage={paginationConfig.dataCount < paginationConfig.dataLength ? paginationConfig.dataCount : paginationConfig.page * paginationConfig.dataLength}
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) => setPaginationConfig({
            ...paginationConfig,
            page: selected + 1
          })}
          onDataLengthChange={(e) => setPaginationConfig({
            ...paginationConfig,
            page: 1,
            dataLength: e.target.value
          })}
        />
      }

      {/* Modal BDP Overhead */}
      <ModalDetailJurnalBDP
        modalConfig={modalConfig}
        setModalConfig={setModalConfig}
        BDPType="Overhead"
      />
    </div>
  )

}

export default PageListBDPOverhead