import React from 'react'
import {
  Modal,
  Row,
  Col
} from 'react-bootstrap'
import {
  DateConvert,
  RupiahConvert
} from 'utilities'

const ModalDetailJurnalBDP = ({ modalConfig, setModalConfig, BDPType }) => {     // BDPTYpe : Bahan|Upah|Subkon|Overhead
  const data = modalConfig?.data

  const InfoItemHorizontal = ({ label, text, width }) => (
    <div className={`d-flex align-items-top`}>
      <div style={{ width: width ? width : 180, fontSize: 14 }}>
        {label}
      </div>
      <div className="pl-3 pr-2" style={{ fontSize: 14 }}>
        :
      </div>
      <div style={{ fontSize: 14, maxWidth: '70%' }}>
        {text}
      </div>
    </div>
  )

  const InfoItemVertical = ({ title, text }) => (
    <div className="mb-2">
      <small className="text-secondary" style={{ opacity: 0.8, textTransform: "capitalize" }}>
        {title}
      </small>
      <div
        className="text-dark"
        style={{ fontWeight: 500, }}
      >
        {text ? text : "-"}
      </div>
    </div>
  )

  const getJumlah = () => {
    if (BDPType === "Bahan") {
      return parseFloat(data?.qty_realisasi_bahan ?? 0) * parseInt(data?.harga_satuan_rata_rata_pakai ?? 0)
    }

    if (BDPType === "Upah") {
      return parseFloat(data?.qty_realisasi_upah ?? 0) * parseInt(data?.harga_satuan_upah ?? 0)
    }

    if (BDPType === "Subkon") {
      return parseFloat(data?.qty_realisasi_subkon ?? 0) * parseInt(data?.harga_satuan_subkon ?? 0)
    }

    if (BDPType === "Overhead") {
      return parseFloat(data?.qty_realisasi_overhead ?? 0) * parseInt(data?.harga_satuan_overhead ?? 0)
    }

    return 0
  }

  return (
    <Modal
      size="md"
      show={modalConfig?.show}
      onHide={() => setModalConfig({ show: false, data: {} })}
      title="Detail Data Jurnal BDP">
      <Modal.Header closeButton>
        <Modal.Title className="text-primary h6">
          Detail Data Jurnal BDP
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InfoItemHorizontal label="Tgl. Realisasi Produksi" text={DateConvert(new Date(data?.tgl_realisasi_produksi)).detail} />
        <InfoItemHorizontal label="No. Realisasi Produksi" text={data?.no_realisasi_produksi} />
        <InfoItemHorizontal label={`Item ${BDPType ?? ""}`} text={data?.nama_item} />
        <InfoItemHorizontal label="Satuan" text={data?.nama_satuan} />
        <InfoItemHorizontal label="Qty. Realisasi" text={data?.qty_realisasi_bahan ?? data?.qty_realisasi_upah ?? data?.qty_realisasi_subkon ?? data?.qty_realisasi_overhead ?? 0} />
        <InfoItemHorizontal
          label="Harga Satuan"
          text={RupiahConvert(String(parseInt(data?.harga_satuan_rata_rata_pakai ?? data?.harga_satuan_upah ?? data?.harga_satuan_subkon ?? data?.harga_satuan_overhead ?? 0))).detail}
        />
        <hr />
        <Row>
          <Col>
            <InfoItemVertical title="Tgl. BDP" text={DateConvert(new Date(data?.tgl_bdp)).detail} />
          </Col>
          <Col><InfoItemVertical title="No. BDP" text={data?.no_bdp} />
          </Col>
        </Row>
        <InfoItemVertical title="Jumlah" text={RupiahConvert(String(parseInt(getJumlah()))).detail} />
        <InfoItemVertical title="COA Debet" text={`${data?.nomor_akun_debet} - ${data?.nama_akun_debit}`} />
        <InfoItemVertical title="COA Kredit" text={`${data?.nomor_akun_kredit} - ${data?.nama_akun_kredit}`} />
        <InfoItemVertical title="Keterangan Jurnal*" text={data?.keterangan_jurnal} />
      </Modal.Body>
    </Modal>
  )
}

export default ModalDetailJurnalBDP