import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card, Nav, Tab } from "react-bootstrap";
import { BackButton, ActionButton, DataStatus, Alert } from "components";
import { HargaPokokProduksiApi } from "api";
import {
  InfoSection,
  TabItem,
  SummaryBDP,
  ModalPostingHPP,
} from "./components";
import { mapBDP, grandTotalBDP } from "./helpers";

const TambahHPP = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id: id_job_order } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataInfo, setDataInfo] = useState({});
  const [dataBDP, setDataBDP] = useState({
    bahan: [],
    upah: [],
    subcont: [],
    // alat_mesin: [],
    overhead: [],
  });
  const [modalConfig, setModalConfig] = useState({ show: false, data: null });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);
    HargaPokokProduksiApi.getSingleProduksiList({ id_job_order })
      .then((res) => {
        const data = res.data.data ?? {};

        setDataInfo({
          id_job_order: data.id_job_order,
          id_realisasi_produksi: data.id_realisasi_produksi,
          tgl_hpp: data.tgl_hpp,
          tgl_sales_order: data.tgl_sales_order,
          tgl_jobmix: data.tgl_job_order,
          tgl_realisasi_produksi: data.tgl_realisasi_produksi,
          no_hpp: data.no_hpp,
          no_sales_order: data.no_sales_order,
          no_jobmix: data.no_job_order,
          no_realisasi_produksi: data.no_realisasi_produksi,
          no_realisasi_produksi: data.no_realisasi_produksi,
          nomor_akun_debet: data.nomor_akun_debet,
          nomor_akun_kredit: data.nomor_akun_kredit,
          item_produksi: data.item_produksi,
          nama_customer: data.nama_customer,
          nama_karyawan: data.nama_karyawan,
          nama_item: data.nama_item,
          nama_akun_debet: data.nama_akun_debet,
          nama_akun_kredit: data.nama_akun_kredit,
          nama_satuan: data.kode_satuan,
          nama_keperluan: data.keperluan_produksi,
          nama_depo: data.nama_depo,
          nama_gudang: data.nama_gudang,
          kode_satuan: data.kode_satuan,
          qty_so: data.qty_item_so,
          qty_jobmix: data.qty_jobmix,
          qty_jobmix_realisasi: data.qty_jobmix_realisasi,
          qty_realisasi_produksi: data.qty_realisasi_produksi,
          qty_produksi: data.qty_produksi,
          qty_telah_direalisasi: data.qty_realisasi_produksi,
          qty_telah_direalisasi: data.qty_realisasi_sum,
          keterangan_jurnal: data.keterangan_jurnal,
          jumlah: data.jumlah,
          status_progress: data.status_job_order,
        });

        setDataBDP({
          bahan: mapBDP(data.bahan),
          upah: mapBDP(data.upah),
          subcont: mapBDP(data.subkon),
          overhead: mapBDP(data.overhead),
        });
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Tidak dapat memuat data!",
        })
      )
      .finally(() => setIsPageLoading(false));
  };

  const calculateGrandTotal = (bahan, upah, subcont, overhead) => {
    const totalBahan = bahan?.reduce((acc, { qty_realisasi, harga_satuan }) => acc + Math.round(parseFloat(qty_realisasi ?? 0) * parseInt(harga_satuan ?? 0)), 0);
    const totalUpah = upah?.reduce((acc, { qty_realisasi, harga_satuan }) => acc + Math.round(parseFloat(qty_realisasi ?? 0) * parseInt(harga_satuan ?? 0)), 0);
    const totalSubcont = subcont?.reduce((acc, { qty_realisasi, harga_satuan }) => acc + Math.round(parseFloat(qty_realisasi ?? 0) * parseInt(harga_satuan ?? 0)), 0);
    const totalOverhead = overhead?.reduce((acc, { qty_realisasi, harga_satuan }) => acc + Math.round(parseFloat(qty_realisasi ?? 0) * parseInt(harga_satuan ?? 0)), 0);
    const grandTotal = totalBahan + totalUpah + totalSubcont + totalOverhead;

    return { totalBahan, totalUpah, totalSubcont, totalOverhead, grandTotal };
  }
  const grandTotal = calculateGrandTotal( dataBDP.bahan, dataBDP.upah, dataBDP.subcont, dataBDP.overhead );

  useEffect(() => {
    setNavbarTitle("Harga Pokok Produksi");
    getInitialData();
  }, []);

  if (isPageLoading) {
    return <DataStatus loading={isPageLoading} text="Memuat Data . . ." />;
  }

  return (
    <>
      <div className="text-right mb-2">
        <BackButton onClick={() => history.goBack()} />
      </div>

      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig((prev) => ({ ...prev, show: false }))}
      />

      {/*  Info Section */}
      <div className="font-weight-bold mb-1">
        Tambah Data Harga Pokok Produksi
      </div>
      <InfoSection data={dataInfo} data_type="hpp_create" />

      {/* Tabs */}
      <div className="mt-3">
        <div className="font-weight-bold mb-1">List Data BDP Produksi</div>
        <Card>
          <Tab.Container defaultActiveKey="bahan">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="bahan">BDP Bahan</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="upah">BDP Upah</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="subcont">BDP Subcont</Nav.Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link eventKey="overhead">BDP Overhead</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>

            <Card.Body>
              <Tab.Content>
                <Tab.Pane eventKey="bahan">
                  <TabItem type="bahan" data={dataBDP.bahan} grandTotal={grandTotal.totalBahan}/>
                </Tab.Pane>
                <Tab.Pane eventKey="upah">
                  <TabItem type="upah" data={dataBDP.upah} grandTotal={grandTotal.totalUpah}/>
                </Tab.Pane>
                <Tab.Pane eventKey="subcont">
                  <TabItem type="subcont" data={dataBDP.subcont} grandTotal={grandTotal.totalSubcont}/>
                </Tab.Pane>
                <Tab.Pane eventKey="overhead">
                  <TabItem type="overhead" data={dataBDP.overhead} grandTotal={grandTotal.totalOverhead}/>
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Tab.Container>
        </Card>
      </div>

      {/* Summary BDP */}
      <div className="mt-3">
        <div className="font-weight-bold mb-1">Summary BDP</div>
        <SummaryBDP grandTotal={grandTotal} />
      </div>

      <div className="d-flex justify-content-end">
        <ActionButton
          size="md"
          text="Posting HPP"
          onClick={() =>
            setModalConfig({
              show: true,
              data: dataInfo,
            })
          }
        />
      </div>

      {/* Modal Posting HPP */}
      {modalConfig.show && (
        <ModalPostingHPP
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          grandTotalBDP={grandTotal}
        />
      )}
    </>
  );
};

export default TambahHPP;
