import React, {
    useState,
    useEffect
  } from 'react'
  import {
    Card,
    Nav,
    Tab,
    Row,
    Col
  } from "react-bootstrap"
  import {
    useHistory,
    useLocation
  } from 'react-router-dom'
  import {
    Alert,
    CRUDLayout,
    ActionButton,
    InputSearch
  } from 'components'
  import { BDPProduksiApi, } from 'api'
  import {
    PageListBDPBahan,
    PageListBDPUpah,
    PageListBDPSubkon,
    PageListBDPOverhead,
  } from "./Tab"
  
  const ListBDPProduksi = ({ setNavbarTitle }) => {
    const history = useHistory()
    const location = useLocation()
  
    const initialPagination = { page: '1', dataLength: '10', totalPage: '1', dataCount: '0' }
    const [paginationConfigBahan, setPaginationConfigBahan] = useState(initialPagination)
    const [paginationConfigUpah, setPaginationConfigUpah] = useState(initialPagination)
    const [paginationConfigSubkon, setPaginationConfigSubkon] = useState(initialPagination)
    const [paginationConfigOverhead, setPaginationConfigOverhead] = useState(initialPagination)
    const [isPageLoading, setIsPageLoading] = useState({
      bahan: false,
      upah: false,
      subkon: false,
      overhead: false,
    })
    const [searchConfig, setSearchConfig] = useState({
      status: false,
      key: ''
    })
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: "primary",
      text: "",
    })
  
    const [dataBahan, setDataBahan] = useState([{}])
    const [dataUpah, setDataUpah] = useState([{}])
    const [dataSubkon, setDataSubkon] = useState([{}])
    const [dataOverhead, setDataOverhead] = useState([{}])
  
    const fetchBahan = () => {
      setIsPageLoading((prev) => ({ ...prev, bahan: true }))
  
      BDPProduksiApi.getBDPBahan({ q: searchConfig.key, page: paginationConfigBahan.page, per_page: paginationConfigBahan.dataLength })
        .then((res) => {
          const data = res?.data?.data ?? []
          const dataBahan = data?.map((val) => Object({
            ...val,
            tgl_realisasi_produksi: val.tgl_realisasi_produksi,
            no_realisasi_produksi: val.no_realisasi_produksi,
            qty_realisasi: val.qty_realisasi_produksi_bahan,
            harga_satuan: val.harga_satuan_rata_rata_pakai,
            tgl_bdp: val.tgl_bdp_bahan,
            no_bdp: val.no_bdp_bahan
          }))
  
          setDataBahan(dataBahan ?? [])
          setPaginationConfigBahan((prev) => ({
            ...prev,
            dataCount: res?.data.data_count,
            totalPage: res?.data.total_page,
          }))
        })
        .finally(() => setIsPageLoading((prev) => ({ ...prev, bahan: false })))
    }
  
    const fetchUpah = () => {
      setIsPageLoading((prev) => ({ ...prev, upah: true }))
  
      BDPProduksiApi.getBDPUpah({ q: searchConfig.key, page: paginationConfigUpah.page, per_page: paginationConfigUpah.dataLength })
        .then((res) => {
          const data = res?.data?.data ?? []
          const dataUpah = data?.map((val) => Object({
            ...val,
            nama_coa_debet: `${val.nomor_akun_debet} - ${val.nama_akun_debit}`,
            nama_coa_kredit: `${val.nomor_akun_kredit} - ${val.nama_akun_kredit}`,
            tgl_bdp: val.tgl_bdp_upah,
            no_bdp: val.no_bdp_upah
          }))
  
          setDataUpah(dataUpah ?? [])
          setPaginationConfigUpah((prev) => ({
            ...prev,
            dataCount: res?.data.data_count,
            totalPage: res?.data.total_page,
          }))
        })
        .finally(() => setIsPageLoading((prev) => ({ ...prev, upah: false })))
    }
  
    const fetchSubkon = () => {
      setIsPageLoading((prev) => ({ ...prev, subkon: true }))
  
      BDPProduksiApi.getBDPSubkon({ q: searchConfig.key, page: paginationConfigSubkon.page, per_page: paginationConfigSubkon.dataLength })
        .then((res) => {
          const data = res?.data?.data ?? []
          const dataSubkon = data?.map((val) => Object({
            ...val,
            nama_coa_debet: `${val.nomor_akun_debet} - ${val.nama_akun_debet}`,
            nama_coa_kredit: `${val.nomor_akun_kredit} - ${val.nama_akun_kredit}`,
            tgl_bdp: val.tgl_bdp_subkon,
            no_bdp: val.no_bdp_subkon
          }))
          setDataSubkon(dataSubkon ?? [])
          setPaginationConfigSubkon((prev) => ({
            ...prev,
            dataCount: res?.data.data_count,
            totalPage: res?.data.total_page,
          }))
        })
        .finally(() => setIsPageLoading((prev) => ({ ...prev, subkon: false })))
    }
  
    const fetchOverhead = () => {
      setIsPageLoading((prev) => ({ ...prev, overhead: true }))
  
      BDPProduksiApi.getBDPOverhead({ q: searchConfig.key, page: paginationConfigOverhead.page, per_page: paginationConfigOverhead.dataLength })
        .then((res) => {
          const data = res?.data?.data ?? []
          const dataOverhead = data?.map((val) => Object({
            ...val,
            tgl_realisasi_produksi: val.tgl_realisasi_produksi,
            no_realisasi_produksi: val.no_realisasi_produksi,
            qty_realisasi: val.qty_realisasi_produksi_overhead,
            harga_satuan: val.harga_satuan_overhead,
            tgl_bdp: val.tgl_bdp_overhead,
            no_bdp: val.no_bdp_overhead
          }))
  
          setDataOverhead(dataOverhead ?? [])
          setPaginationConfigOverhead((prev) => ({
            ...prev,
            dataCount: res?.data.data_count,
            totalPage: res?.data.total_page,
          }))
        })
        .finally(() => setIsPageLoading((prev) => ({ ...prev, overhead: false })))
    }
  
    const onInputSearchChange = e => {
      const key = e.target.value
  
      setSearchConfig((prev) => ({ ...prev, key }))
  
      setPaginationConfigBahan(initialPagination)
      setPaginationConfigUpah(initialPagination)
      setPaginationConfigSubkon(initialPagination)
      setPaginationConfigOverhead(initialPagination)
    }
  
    const checkAlert = () => {
      const locationState = location.state
  
      if (locationState) {
        if (locationState.alert) {
          setAlertConfig({
            show: locationState.alert.show,
            text: locationState.alert.text,
            variant: locationState.alert.variant,
          })
        }
      }
    }
  
    const onDismissAlert = () => {
      const currentState = history.location.state
  
      if (currentState && currentState.alert) {
        let state = { ...currentState }
        delete state.alert
  
        history.replace(history.location.pathname, state)
        setAlertConfig({
          show: false,
          text: '',
          variant: '',
        })
      }
    }
  
    useEffect(() => {
      setNavbarTitle('BDP Produksi')
      checkAlert()
    }, [])
  
    useEffect(() => fetchBahan(), [searchConfig.key, paginationConfigBahan.page, paginationConfigBahan.dataLength])
    useEffect(() => fetchUpah(), [searchConfig.key, paginationConfigUpah.page, paginationConfigUpah.dataLength])
    useEffect(() => fetchSubkon(), [searchConfig.key, paginationConfigSubkon.page, paginationConfigSubkon.dataLength])
    useEffect(() => fetchOverhead(), [searchConfig.key, paginationConfigOverhead.page, paginationConfigOverhead.dataLength])
  
    return (
      <CRUDLayout>
        <CRUDLayout.Head>
          <CRUDLayout.HeadSearchSection>
            <Row className="mb-2">
              <Col md={8}>
                <InputSearch
                  value={searchConfig.key}
                  onChange={onInputSearchChange}
                />
              </Col>
            </Row>
          </CRUDLayout.HeadSearchSection>
          <CRUDLayout.HeadButtonSection>
            <ActionButton text="Tambah Data" onClick={() => history.push('/transaksi/bdp-produksi/list-realisasi-produksi')} />
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>
  
        <Alert
          showCloseButton
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={onDismissAlert}
        />
  
        <Card className='border'>
          <Tab.Container defaultActiveKey='bdp_bahan'>
            <Card.Header className='className="d-flex justify-content-between align-items-center'>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="bdp_bahan">BDP Bahan</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="bdp_upah">BDP Upah</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="bdp_subkon">BDP Subkon</Nav.Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link eventKey="bdp_overhead">BDP Overhead</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Tab.Content>
              <Tab.Pane eventKey="bdp_bahan">
                <PageListBDPBahan
                  dataBDPBahan={dataBahan}
                  paginationConfig={paginationConfigBahan}
                  setPaginationConfig={setPaginationConfigBahan}
                  searchConfig={searchConfig}
                  isPageLoading={isPageLoading}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="bdp_upah">
                <PageListBDPUpah
                  dataBDPUpah={dataUpah}
                  paginationConfig={paginationConfigUpah}
                  setPaginationConfig={setPaginationConfigUpah}
                  searchConfig={searchConfig}
                  isPageLoading={isPageLoading} />
              </Tab.Pane>
              <Tab.Pane eventKey="bdp_subkon">
                <PageListBDPSubkon
                  dataBDPSubkon={dataSubkon}
                  paginationConfig={paginationConfigSubkon}
                  setPaginationConfig={setPaginationConfigSubkon}
                  searchConfig={searchConfig}
                  isPageLoading={isPageLoading}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="bdp_overhead">
                <PageListBDPOverhead
                  dataBDPOverhead={dataOverhead}
                  paginationConfig={paginationConfigOverhead}
                  setPaginationConfig={setPaginationConfigOverhead}
                  searchConfig={searchConfig}
                  isPageLoading={isPageLoading}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card>
      </CRUDLayout>
    )
  }
  
  export default ListBDPProduksi