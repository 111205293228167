import Services from "services"

class TransferOverheadProduksiApi {
  get(params) {
    return Services.get("/transfer_produksi_overhead/all_transfer", { params })
  }

  getPreform(params) {
    return Services.get("/transfer_produksi_overhead/page", { params })
  }

  getSingle(params) {
    return Services.get("/transfer_produksi_overhead/single", { params })
  }

  getHistory(params) {
    return Services.get("/transfer_produksi_overhead/transfer", { params })
    // return Services.get("/transfer_produksi_overhead/histori", { params })
  }

  getNomor(params) {
    return Services.get("/transfer_produksi_overhead/no_baru", { params })
  }

  getDropdown(params) {
    return Services.get("/transfer_produksi_overhead/dropdown", { params })
  }

  save(data) {
    return Services.post("/transfer_produksi_overhead", data)
  }
}

export default new TransferOverheadProduksiApi()
